<template>
	<div>
		<myNav/>
			<div class="b_content">
				<iframe :src="list" style="height: 1000px;" width="1160" height="500"></iframe>
			</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				list:[]
			}
		},
		created(){
			this.dataAll()
		},
		methods: {
			//获取pdf接口数据局
			dataAll(){
				this.$axios({
					url:'Sysexplain/system',
					method:'post'
				}).then(res=>{
					console.log(res.data.data[0].systemexplain)
					this.list=res.data.data[0].systemexplain
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.b_content{
		width: 1200px;
		background-color: #FFFFFF;
		margin: auto;
		padding: 20px;
		box-sizing: border-box;
	}
</style>
