<template>
	<div>
		<myNav />
		<!-- <loginPop/> -->
		<div class="tt_content">
			<div class="a_title1" style="margin-bottom: 30px;">
				<div v-for="(item,index) in listData" :key="index" :class="index1==index ? 'a_title2' : 'aa_title2'"
					@click="fen(index,item)">{{item.name}}</div>
			</div>
			<div v-if="index1==0">
				<div class="a_title5">
					<div class="a_title6">办学性质</div>
					<div :class="isCheck1==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.bxxz"
						:key="index" @click="isOk1(index,index,1)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">办学类型</div>
					<div :class="isCheck2==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.bf_type"
						:key="index" @click="isOk2(index,index,2)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck3==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.province"
							:key="index" @click="isOk3(index,index,3)">{{item}}</div>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck4==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.stype"
							:key="index" @click="isOk4(index,index,4)">{{item}}</div>
					</div>
				</div>
				<div class="a_title9" style="margin-top: 40px;">
					<div class="a_title10" style="height: 55px;width: 200px;">
						院校名称
					</div>
					<div class="a_title10" style="height: 55px;width: 280px;">
						专业
					</div>
					<!-- <div class="a_title10" style="height: 55px;" :style="index==1||index==0 ? {'width':'200px'} : ''" -->
					<div class="a_title10" style="height: 55px;" 
						v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div v-loading="loading" :style="loading ? {'margin-top':'100px'} : ''">
					<div class="a_title9" v-for="(item,index) in tableData.list" :key="index">
						<div class="a_title10" style="background-color: #FFFFFF;width: 200px;font-size: 12px;">
							{{item.school_name ? item.school_name : '-'}}
						</div>
						<div class="a_title10"
							style="background-color: #FFFFFF;width: 280px;font-size: 12px;">{{item.title}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.year ? item.year : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.batch ? item.batch : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.highest_score ? item.highest_score : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.avg ? item.avg : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.lowest_score ? item.lowest_score : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.lowest_order ? item.lowest_order : '-'}}
						</div>
					</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="tableData.count"
						:page-size="20" :pager-count="7" :current-page="tableData.page" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
			<div v-if="index1==1">
				<div class="a_title5">
					<div class="a_title6">办学性质</div>
					<div :class="isCheck1==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.bxxz"
						:key="index" @click="isOk1(index,index,1)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">办学类型</div>
					<div :class="isCheck2==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.bf_type"
						:key="index" @click="isOk2(index,index,2)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck3==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.province"
							:key="index" @click="isOk3(index,index,3)">{{item}}</div>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck4==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in myData.stype"
							:key="index" @click="isOk4(index,index,4)">{{item}}</div>
					</div>
				</div>
				<div class="a_title9" style="margin-top: 40px;">
					<div class="a_title10" style="height: 55px;width: 200px;">
						院校名称
					</div>
					<div class="a_title10" style="height: 55px;width: 280px;">
						专业
					</div>
					<div class="a_title10" style="height: 55px;" 
						v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div v-loading="loading" :style="loading ? {'margin-top':'100px'} : ''">
					<div class="a_title9" v-for="(item,index) in tableData.list" :key="index">
						<div class="a_title10" style="background-color: #FFFFFF;width: 200px;font-size: 12px;">
							{{item.school_name ? item.school_name : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;width: 280px;font-size: 12px;">
							{{item.title}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.year ? item.year : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.batch ? item.batch : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.highest_score ? item.highest_score : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.avg ? item.avg : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.lowest_score ? item.lowest_score : '-'}}
						</div>
						<div class="a_title10" style="background-color: #FFFFFF;font-size: 12px;">
							{{item.lowest_order ? item.lowest_order : '-'}}
						</div>
					</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="tableData.count"
						:page-size="10" :pager-count="7" :current-page="tableData.page" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				list: {
					school_type: '',
					type: '',
					is_pubilc: 0,
					province_id: '',
					is_new: 0,
					page: 1
				},
				form: {},
				myData: [],
				index1: 0,
				isCheck1: 0,
				isCheck2: null,
				isCheck3: null,
				isCheck4: null,
				listData: [{
					name: '专业分数线(文理)'
				}, {
					name: '专业分数线(新高考)'
				}],
				titleData: [ //{
					//name: '院校名称'
					//}, {
					//name: '专业'
					//}, 
					{
						name: '年份'
					}, {
						name: '批次'
					}, {
						name: '最高分'
					}, {
						name: '评价分'
					}, {
						name: '最低分'
					}, {
						name: '最低位次'
					}
				],
				tableData: [],
				loading: true
			};
		},
		activated() {
			//无需登录
			//if(!this.$store.state.userData.token) return 
			this.dataAll()
			this.searchShcool(this.list)
		},
		methods: {
			//获取所有数据
			dataAll() {
				this.$axios({
					url: 'school/searchCondition',
					methods: 'post'
				}).then(res => {
					this.myData = res.data.data
				})
				this.$axios({
					url: 'school/get_school_line',
					methods: 'post',
					data: {
						page: 1,
						limit: 10
					}
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//专业分数线和新高高
			fen(e) {
				this.tableData = []
				this.loading = true
				this.index1 = e
				this.isCheck1 = 0
				this.isCheck2 = null
				this.isCheck3 = null
				this.isCheck4 = null
				this.list.page = 1
				this.$axios({
					url: 'school/searchCondition',
					methods: 'post'
				}).then(res => {
					this.myData = res.data.data
				})
				this.$axios({
					url: 'school/get_school_line',
					methods: 'post',
					data: {
						page: 1,
						is_new: e,
						is_pubilc: 0
					}
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//选中状态
			isOk2(e, a) {
				this.isCheck2 = e
				this.list.school_type = a
				this.list.page = 1
				this.searchShcool(a, this.list)
			},
			//选中状态
			isOk3(e, a) {
				this.isCheck3 = e
				this.list.province_id = a
				this.list.page = 1
				this.searchShcool(a, this.list)
			},
			//选中状态
			isOk4(e, a) {
				this.isCheck4 = e
				this.list.type = a
				this.list.page = 1
				this.searchShcool(a, this.list)
			},
			//选中状态
			isOk1(e, a) {
				this.isCheck1 = e
				this.list.is_pubilc = a
				this.list.page = 1
				this.searchShcool(a, this.list)
			},
			//查询数据
			searchShcool(data, value) {
				this.tableData = []
				this.loading = true
				this.$axios({
					url: 'school/get_school_line',
					methods: 'post',
					data: value
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//分页
			currentChange(e) {
				this.tableData = []
				this.loading = true
				this.list.page = e
				this.$axios({
					url: 'school/get_school_line',
					methods: 'post',
					data: this.list
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.tt_content {
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;

		.a_title1 {
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;

			.a_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
				border-bottom: 2px solid #3B68DF;
				color: #3B68DF;
			}

			.aa_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
			}
		}

		.a_all {
			padding-left: 20px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			margin: 30px 0px;

			.a_input {
				width: 817px;
				height: 42px;
				background: #F2F8FF;
				border: none;
				outline: none;
				padding-left: 20px;
				box-sizing: border-box;
			}

			.a_title3 {
				display: flex;
				align-items: center;
				width: 130px;
				height: 40px;
				background: #3B68DF;
				justify-content: center;

				.iconfont {
					color: #FFFF00;
				}

				.a_title4 {
					font-size: 18px;
					font-weight: 400;
					margin-left: 5px;
					color: #FFFFFF;
				}
			}
		}

		.a_title5 {
			display: flex;
			margin-bottom: 20px;
			flex-wrap: wrap;

			.a_title6 {
				margin-right: 40px;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
				width: 100px;
				text-align: right;
			}

			.a_title7:hover {
				cursor: pointer;
			}

			.a_title7 {
				padding: 5px 23px;
				background: #3B68DF;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				margin-bottom: 10px;
			}

			.a_title8:hover {
				color: #FFFFFF;
				background-color: #3B68DF;
				cursor: pointer;
			}

			.a_title8 {
				padding: 5px 23px;
				background: #F7F7F7;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				margin-bottom: 10px;
			}
		}

		.a_title9 {
			display: flex;
			justify-content: center;

			.a_title10 {
				width: 110px;
				height: 45px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
			}
		}
	}
</style>
