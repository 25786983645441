<template>

	<div class="t_content" v-if="temp_app" @touchmove.prevent @mousewheel.prevent :style="{'height':myHeight}">

		<div class="box">

			<div class="f_title">请登录</div>
			<div class="oper">
				<div class="slogan">您还未登录</div>
				<div class="slogan-1">（请先登录/注册再进行此操作）</div>
				<div class="go" @click="goLogin">登录</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			isShow: {
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				myHeight: '',
				screnHeight: '',
				temp_app: true
			}
		},
		created() {

		},
		activated() {
			//获取屏幕高度和整个页面高度，作比较
			this.screnHeight = document.documentElement.clientHeight
			this.myHeight = document.body.scrollHeight
			if (this.screnHeight >= document.body.scrollHeight) {
				this.myHeight = this.screnHeight + 'px'
			} else {
				this.myHeight = document.body.scrollHeight + 'px'
			}
			if (this.$store.state.userData.token) {
				this.temp_app = false
			} else {
				this.temp_app = true
			}
		},
		methods: {
			goLogin() {
				this.$router.push({
					path: '/Login'
				})
			}
			//点击关闭
			
		}
	}
</script>

<style scoped>
	.t_content {
		background-color: rgba(0, 0, 0, .5);
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99999;
	}

	.box {
		width: 740px;
		margin: 15% auto 0 auto;
		text-align: center;
	}

	.title {
		height: 150px;
		line-height: 150px;
		background-color: #337CFF;
		font-size: 33px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.f_title {
		height: 150px;
		line-height: 150px;
		background-color: #337CFF;
		font-size: 33px;
		font-weight: 400;
		color: #FFFFFF;

		/* .f_title11 {
					font-size: 16px;
					font-weight: 400;
					color: #337cff;
					text-align: left;
					margin-left: 20px;
				}
				
				.f_title11:hover {
					color: #F58235;
					cursor: pointer;
				} */

		.f_title12 {
			font-size: 16px;
			font-weight: 400;
			color: #337cff;
			margin-right: 20px;
		}

		.f_title12:hover {
			color: #F58235;
			cursor: pointer;
		}
	}

	.oper {
		background-color: #FFFFFF;
		padding: 45px 0 45px;
	}

	.slogan {
		font-size: 27px;
		font-weight: 400;
		color: #999999;
	}

	.slogan-1 {
		font-size: 21px;
		font-weight: 400;
		color: #999999;
		margin-top: 20px;
	}

	.go {
		width: 312px;
		height: 50px;
		background: #337CFF;
		opacity: 1;
		border-radius: 2px;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 50px;
		margin: 35px auto 0 auto;
	}

	.go:hover {
		color: #F58235;
		cursor: pointer;
	}
</style>
