<template>
	<div>
		<myNav />
		<!-- <login-pop/> -->
		<div class="content">
			<div>
				<!-- <div class="f_content">省份榜单：</div>
				<div class="f_all">
					<div class="f_title1" v-for="(item,index) in 19" :key="index">安徽榜</div>
				</div> -->
				<div style="padding-top: 20px;background-color: #FFFFFF" v-for="(item,index) in list" :key="index">
					<div class="f_content">
						<div class="f_title2">专业类别：</div>
						<div class="f_title3">艺术类综合排名</div>
					</div>
					<div class="f_all">
						<div :class="isCheck==indexs ? 'f_title2' : 'f_title1'" @click="isOk(i,indexs)"
							v-for="(i,indexs) in item.children" :key="indexs">{{i.name}}</div>
					</div>
				</div>
				<div class="g_content"
					style="background-color: #FFFFFF;border: 1px #F5F5F5; border-bottom: 1px solid #F5F5F5;border-top: 1px solid #F5F5F5;">
					<!-- <div class="g_title9" style="width: 233px;">ID</div> -->
					<div class="g_title9" style="width: 280px;">专业名称</div>
					<div class="g_title9" style="width: 233px;">学位名称</div>
					<div class="g_title9" style="width: 200px;">男生人数</div>
					<div class="g_title9" style="width: 200px;">女生人数</div>
					<div class="g_title9" style="width: 160px;">学年制</div>
					<div class="g_title9" style="width: 233px;">查看专业详情</div>
				</div>
				<div>
					<div class="g_content"
						style="border-bottom: 1px solid #F5F5F5;background-color: #FFFFFF;margin-top:0px;"
						v-for="(res,s) in myData.data" :key="s">
						<!-- <div class="g_title9" style="color: #999999;width: 233px;border-right: 1px solid #F5F5F5;">{{res.id}}</div> -->
						<div class="g_title9" style="color: #999999;width: 280px;border-right: 1px solid #F5F5F5;">
							{{res.major_title}}</div>
						<div class="g_title9" style="color: #999999;width: 233px;border-right: 1px solid #F5F5F5;">
							{{res.degree}}</div>
						<div class="g_title9" style="color: #999999;width: 200px;border-right: 1px solid #F5F5F5;">
							{{res.man_ratio}}</div>
						<div class="g_title9" style="color: #999999;width: 200px;border-right: 1px solid #F5F5F5;">
							{{res.woman_ratio}}</div>
						<div class="g_title9" style="color: #999999;width: 160px;border-right: 1px solid #F5F5F5;">
							{{res.system}}</div>
						<div class="g_title9" style="color: #337CFF;width: 233px;border-right: 1px solid #F5F5F5;"
							@click="artDetail(res.id)">查看详情</div>
					</div>
				</div>
				<div class="search_all">
					<el-pagination background layout="prev,pager,next" :total="myData.total" :page-size="10"
						:pager-count="7" :current-page="myData.current_page" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				list: [],
				isCheck: 0,
				myData: [],
				item: {}
			}
		},
		created() {
			this.dataAll(101)
		},
		methods: {
			//专业详情/ArtSchool
			artDetail(e) {
				// this.$router.push({path:'/art/isDetail',query:{id:e}})
				this.$router.push({
					path: '/art/details',
					query: {
						id: e
					}
				})
			},
			//选中状态
			isOk(a, e) {
				this.isCheck = e
				this.item = a
				this.$axios({
					url: 'major/index',
					method: 'post',
					data: {
						level_id: a.level_id,
						page: 1
					}
				}).then(res => {
					this.myData = res.data.data
				})
			},
			//改变当前页的方法
			currentChange(val) {
				this.$axios({
					url: 'major/index',
					method: 'post',
					data: {
						level_id: this.item.level_id,
						page: val
					}
				}).then(res => {
					this.myData = res.data.data
				})
			},
			dataAll() {
				this.$axios({
					url: 'art_major/group',
					method: 'post',
				}).then(res => {
					this.list = res.data.data
					this.$axios({
						url: 'major/index',
						method: 'post',
						data: {
							level_id: this.list[0].children[0].level_id
						}
					}).then(req => {
						this.myData = req.data.data
					})
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.a_all {
			padding: 35px 19px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.a_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}

			.a_title2 {
				display: flex;
				align-items: center;

				.a_title3 {
					margin: 47px 0px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
				}

				.a_title4 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
			}

			.a_title5 {
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				display: flex;

				.a_title6 {
					width: 130px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					display: flex;
				}

				.a_title7 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}

				.a_title8 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #337CFF;
					margin-left: 47px;
				}
			}
		}

		.b_all {
			display: flex;
			align-items: center;
			margin: 58px 0px 23px 34px;

			.b_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
			}

			.b_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #3B68DF;
			}
		}

		.c_content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 36px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F5F5F5;

			.c_all {
				display: flex;
				align-items: center;

				.c_img {
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}

				.c_title1 {
					text-align: left;

					.c_title2 {
						font-size: 20px;
						font-weight: 400;
						color: #333333;
					}

					.c_title3 {
						margin: 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #999999;
					}

					.c_title4 {
						display: flex;
						align-items: center;

						.c_title5 {
							width: 41px;
							height: 24px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #337CFF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.c_title6 {
							width: 37px;
							height: 24px;
							background: #FFF1F0;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #FF4D4F;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.c_title7 {
							width: 33px;
							height: 24px;
							background: #DDF7E2;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #3DB159;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.c_title8 {
							width: 55px;
							height: 24px;
							background: #F0F5FF;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #3B68DF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}

			.cc_all {
				display: flex;

				.cc_img {
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}

				.cc_title1 {
					.cc_title2 {
						font-size: 20px;
						font-weight: 400;
						color: #333333;
						text-align: left;
					}

					.cc_title3 {
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						margin: 12px 0px;
						text-align: left;
					}

					.cc_title4 {
						display: flex;
						flex-wrap: wrap;

						.cc_title5 {
							background: #F0F5FF;
							padding: 5px 10px;
							border-radius: 14px;
							margin-right: 12px;
							margin-bottom: 12px;
							font-size: 12px;
							font-weight: 400;
							color: #337CFF;
						}
					}
				}
			}

			.c_title9 {
				.iconfont {
					color: #FECB1E;
					font-size: 30px;
				}

				.c_title10 {
					font-size: 30px;
					font-weight: 400;
					margin-left: 9px;
					color: #FECB1E;
				}
			}
		}

		.search_all {
			height: 179px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF;

			.search {
				width: 350px;
				height: 83px;
				background: #337CFF;
				opacity: 1;
				border-radius: 7px;
				font-size: 28px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.a_content {
			display: flex;
			margin-top: 50px;
			height: 100px;
			align-items: center;

			.a_title9 {
				font-size: 30px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}

		.d_content {
			width: 1400px;
			background: #F0F5FF;
			border: 5px solid #407EF6;
			padding: 35px 45px;
			box-sizing: border-box;
			margin: 35px 0px;

			.d_title1 {
				font-size: 24px;
				font-weight: 500;
				color: #337CFF;
				text-align: left;
			}

			.d_title2 {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 35px;
				color: #666666;
				margin-top: 20px;
				text-align: left;
			}
		}

		.f_content {
			width: 1200px;
			height: 53px;
			background: #F2F8FF;
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			padding: 23px 49px;
			box-sizing: border-box;
			display: flex;
			text-align: left;
			align-items: center;

			.f_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
			}

			.f_title3 {
				font-size: 20px;
				font-weight: 400;
				color: #337CFF;
				margin-left: 58px;
			}
		}

		.f_all {
			display: flex;
			padding: 23px 49px 0px 49px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			width: 1200px;
			flex-wrap: wrap;

			.f_title1 {
				font-size: 16px;
				font-weight: 400;
				color: #999999;
				margin: 0px 40px 23px 0px;
			}

			.f_title2 {
				font-size: 16px;
				font-weight: 400;
				color: royalblue;
				margin: 0px 40px 23px 0px;
			}
		}

		.g_content {
			display: flex;
			// margin-top: 50px;
			height: 100px;
			align-items: center;

			.g_title9 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}

		.k_img {
			width: 1200px;
			height: 549px;
			object-fit: cover;
		}

		.k_content {
			display: flex;
			justify-content: space-between;
			background-color: #FFFFFF;
			padding: 40px 3px;
			box-sizing: border-box;
			margin-top: 30px;

			.k_all {
				text-align: center;

				.k_title1 {
					width: 163px;
					height: 163px;
					background: #337CFF;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;

					.iconfont {
						color: #FFFFFF;
						font-size: 60px;
					}
				}

				.k_title2 {
					margin-top: 23px;
					font-size: 24px;
					font-weight: 400;
					color: #000000;
				}
			}
		}

		.k_title3 {
			width: 1200px;
			height: 55px;
			background: #F2F8FF;
			padding-left: 49px;
			box-sizing: border-box;
			font-size: 22px;
			font-weight: 400;
			color: #333333;
			display: flex;
			align-items: center;
		}

		.k_title4 {
			background-color: #FFFFFF;
			padding: 0px 49px 30px 49px;
			box-sizing: border-box;

			.k_title5 {
				font-size: 18px;
				font-weight: 400;
				color: #999999;
				padding-top: 23px;
				text-align: left;
			}
		}
	}
</style>
