<template>
	<div class="content">
		<div class="top">
			<div class="m_content">
				<div class="k_all">
					<div><img class="a_title1" :src="myData.PClogo ? myData.PClogo.img : ''" alt=""></div>
				</div>
			</div>
			<div class="a1_content">
				<div class="aa_all">
					<div style="display: flex;align-items: center;">
						<div class="aa_title3">院校</div>
						<i class="iconfont icon-xiala2" style="font-size:12px;color:#CCCCCC;margin-right:6px;margin-top:2px;"></i>
						<div><input v-model="value" style="border:none;outline:none;" type="text" placeholder="请输入您要搜索的内容" class="a1_title4"></div>
					</div>
					<div class="aa_title2" @click="getList()">
						<div><i class="iconfont icon-icon-xian-02" style="color:#FFFFFF;margin-right:10px;font-size:20px"></i></div>
						<div class="aa_title4">搜索</div>
					</div>
				</div>
				<div class="a1_title3" @click="pdf()">系统使用说明</div>
				<div class="a1_title5" @click="myvip()">
					<!-- <span class="a1_title6"></span> -->
					<span class="a1_title7">开通VIP</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {
			
		},
		props: ['myValue'],
		data() {
			return {
				value:'',
				isVip:'',
				yesData:[{name:'是'},{name:'否'}],
				check:0,
				myData:[{img:''}],
				filter: {
					school_type: '',
					is_pubilc: '',
					dual_class: '',
					f211: '',
					f985: '',
					type: ''
				},
			}
		},
		activated(){
			this.dataAll()
		},
		methods: {
			//搜索条件
			getList () {
				if(!this.value) return this.$message({message:'查询内容不能为空',type:'warning'})
				if(!this.$store.state.userData.token){
					this.$message({message:'你还没有登录',type:'warning'})
					this.$router.push({path:'Login'})
				}else{
					this.$axios({
						url:'School/index',
						method:'post',
						data:{title:this.value}
					}).then(res=>{
						let a=res.data.data.data
						if(res.data.status==200){
							this.$store.commit('shcoolAllData',a)
							this.$router.push({path:'/search/souSuo'})
						}
					})
				}
			},
			//获取所有数据
			dataAll(){
				this.$axios({
					url:'Pcimg/pcxxx',
					methods:'post'
				}).then(res=>{
					this.myData=res.data.data
				})
				this.$axios({
					url:'member/getMemberInfo',
					methods:'post'
				}).then(res=>{
					this.isVip=res.data.data
				})
				//VIP开通
				this.$axios({
					url:'member/getMemberInfo',
					methods:'post',
					data:{
						member_id:this.$store.state.userData.user_id
					}
				}).then(res=>{
					this.$store.commit('loginVip',res.data.data.is_vip)
				})
				//搜索学校数据
				this.$axios({
					url:'Pcimg/pcxxx',
					methods:'post'
				}).then(res=>{
					this.myData=res.data.data
				})
			},
			isCheck(e){
				this.check=e
			},
			//开通VIP
			myvip(){
				this.$router.push({path:'/myVip'})
			},
			//跳转系统说明pdf文件
			pdf(){
				this.$router.push({path:'/main/pdf'})	
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	.content {
		width: 1200px;
		margin: auto;
	}

	.top {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		justify-content: space-between;
		.m_content {
			margin-right: 70px;
			.k_all {
				display: flex;
				align-items: center;

				.a_title1 {
					//width:260px;
					height:64px;
					font-weight: 400;
					color: #666666;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.a_title2 {
					font-size: 30px;
					font-weight: 600;
					color: #F94D28;
					margin-left: 14px;
				}
			}
		}

		.a1_content {
			display: flex;

			.aa_all {
				display: flex;
				justify-content: space-between;
				width:600px;
				height:38px;
				border: 3px solid #406BD7;
				border-radius:50px;
				align-items: center;
                background-color: #FFFFFF;
				border-right: 0px;
				.a1_title4{
					height: 38px;
					width: 320px;
				}
				.aa_title3 {
					font-size:14px;
					font-weight: 400;
					color: #666666;
					margin-left: 23px;
					margin-right: 5px;
				}
				.aa_title2{
					border:3px solid #406BD7;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 120px;
					height:38px;
					border-radius:50px;
					background: #3B68DF;
					.aa_title4{
					font-size:16px;
					font-weight: 400;
					color: #FFFFFF;
					}
					.aa_title4:hover{
						color:#F58235;
						cursor: pointer;
					}
				}
			}
			.a1_title3:hover{
				color: #F58235;
				cursor: pointer;
			}
			.a1_title3 {
				border: 3px solid #3B68DF;			
				border-radius: 9999px;
				width: 150px;
				height: 38px;
				background: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 400;
				color:#337CFF;
				margin-left: 20px;
			}
			.a1_title5{
				border-radius: 9999px;
				width: 120px;
				height: 38px;
				border: 3px solid #337CFF;
				background:#337CFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 10px;
				.a1_title6{
				font-size: 12px;
				font-weight: 400;
				color:#FFFFFF;	
				}
				.a1_title7{
				font-size: 16px;
				font-weight: 600;
				color:#FFFFFF;	
				}
				.a1_title7:hover{
					color: #F58235;
					cursor: pointer;
				}
				.a1_title6:hover{
					color: #F58235;
				}
			}
		}
	}
</style>
