<template>
    <div class="content" v-if="appVip" @touchmove.prevent @mousewheel.prevent :style="{'height':myHeight}">
        <div class="box">
            <div class="title">开通VIP</div>
            <div class="oper">
                <div class="slogan">您还没有开通VIP</div>
                <div class="slogan-1">（开通VIP再进行此操作）</div>
                <div class="go" @click="vipGo()">去开通</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
				myHeight:'',
				screnHeight:'',
				appVip:true
            }
        },
		activated() {
			//获取屏幕高度和整个页面高度，作比较
			this.screnHeight=document.documentElement.clientHeight
			this.myHeight=document.body.scrollHeight
			if(this.screnHeight>=document.body.scrollHeight){
				this.myHeight=this.screnHeight + 'px'
			}else{
				this.myHeight=document.body.scrollHeight + 'px'
			}
			if(this.$store.state.userData.userInfo.isvip!=1){
				this.appVip=true
				} else{
				this.appVip=false	
				}
		},
        methods: {
            vipGo () {
                this.$router.push({
                    path: '/myVip'
                })
            }
        }
    }
</script>

<style scoped>
    .content {
        background-color: rgba(0,0,0,.5);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
    .box {
        width: 740px;
        margin: 15% auto 0 auto;
        text-align: center;
    }
    .title {
        height: 150px;
        line-height: 150px;
        background-color: #337CFF;
        font-size: 33px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .oper {
        background-color: #FFFFFF;
        padding: 45px 0 45px;
    }
    .slogan {
        font-size: 27px;
        font-weight: 400;
        color: #999999;
    }
    .slogan-1 {
        font-size: 21px;
        font-weight: 400;
        color: #999999;
        margin-top: 20px;
    }
    .go {
        width: 312px;
        height: 50px;
        background: #337CFF;
        opacity: 1;
        border-radius: 2px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        margin: 35px auto 0 auto;
    }
</style>
