<template>
	<div>
		<myNav />
		<!-- <login-pop/> -->
		<!-- 跳转登录 -->
		<div class="tt_content">
			<div class="a_title1">
				<div v-for="(item,index) in listData" :key="index" :class="index1==index ? 'a_title2' : 'aa_title2'"
					@click="fen(index)">{{item.name}}</div>
			</div>
			<div v-if="index1==0">
				<div class="a_title5" style="margin-top: 40px;">
					<div class="a_title6">查询省份</div>
					<div style="width: 1000px;display: flex;align-items: center;flex-wrap: wrap;">
						<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in additData"
							:key="index" @click="isOk(index,item.id)">{{item.title}}</div>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">年份</div>
					<div :class="isCheck1==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in yearData"
						:key="index" @click="isOk1(index,item)">{{item}}</div>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div v-for="(item,index) in tableData" :key="index">
					<div class="a_title9" v-for="(i,index1) in item.data" :key="index1">
						<div class="a_title10" style="background-color: #FFFFFF;">{{i.province}}</div>
						<div class="a_title10" style="background-color: #FFFFFF;">{{i.type_name}}</div>
						<div class="a_title10" style="background-color: #FFFFFF;">{{i.year}}</div>
						<div class="a_title10" style="background-color: #FFFFFF;">{{i.batch}}</div>
						<div class="a_title10" style="background-color: #FFFFFF;">{{i.fractional}}</div>
					</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="tableData.length"
						:page-size="pageSize" :current-page="pages" @size-change="handleSizeChange"
						@current-change="currentChange1">
					</el-pagination>
				</div>
			</div>
			<div v-if="index1==1">
				<div class="a_all">
					<div style="display: flex;align-items: center;margin-right: 30px;">
						<div><input style="width: 402px;" class="a_input" type="text" placeholder="请输入院校名称"></div>
						<div class="a_title3">
							<i class="iconfont icon-search"></i>
							<span class="a_title4">搜索</span>
						</div>
					</div>
					<div style="display: flex;align-items: center;">
						<div><input style="width: 402px;" class="a_input" type="text" placeholder="请输入专业名称"></div>
						<div class="a_title3">
							<i class="iconfont icon-search"></i>
							<span class="a_title4">搜索</span>
						</div>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">招生地区</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">山东</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">科目</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 2" :key="index"
						@click="isOk(index)">理科</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">安徽</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">理工类</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">年份</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">2050</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">分数</div>
					<div><input class="p_input" type="text" placeholder="请输入分数"></div>
					<div>
						<el-form style="display: flex;align-items: center;margin-top:23px;" :inline="true"
							:model="formInline" class="demo-form-inline">
							<el-form-item>
								<el-select v-model="formInline.name" placeholder="无浮动区间">
									<el-option label="区域一" value="shanghai"></el-option>
									<el-option label="区域二" value="beijing"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div class="sou">搜索</div>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div class="a_title9" v-for="(item,index) in 6" :key="index">
					<div class="a_title10" style="background-color: #FFFFFF;" v-for="(item,index) in tableData"
						:key="index">{{item.name}}</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="100" :page-size="4"
						:pager-count="7" :current-page="1" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				pageSize: 10,
				pages: 1,
				additData: [],
				yearData: [],
				formInline: {
					name: ''
				},
				index1: 0,
				isCheck: 0,
				isCheck1: 0,
				list: {
					year: '',
					province_id: '',
					page: 1
				},
				listData: [{
					name: '分数线(文理)'
				}],
				titleData: [{
					name: '地区'
				}, {
					name: '科目'
				}, {
					name: '年份'
				}, {
					name: '批次'
				}, {
					name: '控制分数线'
				}],
				tableData: [{
					name: '北京大学'
				}, {
					name: '理科实验班(化学…)'
				}, {
					name: '2019'
				}, {
					name: '本科批'
				}, {
					name: '-'
				}]
			};
		},
		activated() {
			this.list.page = 1
			//无需登录
			//if (!this.$store.state.userData.token) return
			this.dataAll(11, 2020)
		},
		methods: {
			fen(e) {
				this.index1 = e
			},
			//省份选中状态
			isOk(e, val) {
				this.isCheck = e
				this.list.province_id = val
				this.list.page = 1
				this.$axios({
					url: 'batch',
					method: 'post',
					data: this.list
				}).then(res => {
					this.tableData = res.data.data.list
					this.AfterChangeData()
				})
			},
			//年份选中状态
			isOk1(e, val) {
				this.isCheck1 = e
				this.list.year = val
				this.list.page = 1
				this.$axios({
					url: 'batch',
					method: 'post',
					data: this.list
				}).then(res => {
					this.tableData = res.data.data.list
					//this.AfterChangeData()
				})
			},
			//获取所有数据
			dataAll(a, b) {
				this.list = {
					year: b,
					province_id: a
				}
				this.$axios({
					url: 'batch/search',
					method: 'post',
				}).then(res => {
					this.yearData = res.data.data.year
					this.additData = res.data.data.pro
				})
				this.$axios({
					url: 'batch',
					method: 'post',
					data: this.list
				}).then(res => {
					this.tableData = res.data.data.list
					//this.AfterChangeData()
				})
			},
			//分数线分页
			currentChange1(val) {
				this.pages = val
			},
			//分页大小改变
			handleSizeChange(val) {
				this.pageSize = val
			}
		}
	}
</script>

<style lang="less" scoped>
	.tt_content {
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;

		.a_title1 {
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;

			.a_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
				border-bottom: 2px solid #3B68DF;
				color: #3B68DF;
			}

			.aa_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
			}
		}

		.a_all {
			padding-left: 20px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			margin: 30px 0px;

			.a_input {
				width: 817px;
				height: 42px;
				background: #F2F8FF;
				border: none;
				outline: none;
			}

			.a_title3 {
				display: flex;
				align-items: center;
				width: 130px;
				height: 40px;
				background: #3B68DF;
				justify-content: center;

				.iconfont {
					color: #FFFF00;
				}

				.a_title4 {
					font-size: 18px;
					font-weight: 400;
					margin-left: 5px;
					color: #FFFFFF;
				}
			}
		}

		.a_title5 {
			display: flex;
			margin-bottom: 23px;
			width: 1200px;

			.a_title6 {
				margin-right: 40px;
				font-size: 20px;
				font-weight: 400;
				color: #333333;
				width: 100px;
				text-align: right;
			}

			.p_input {
				width: 156px;
				height: 40px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				border: none;
				outline: none;
				margin-right: 20px;
			}

			.sou {
				width: 86px;
				height: 35px;
				background: #3B68DF;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.a_title7:hover {
				cursor: pointer;
			}

			.a_title8:hover {
				cursor: pointer;
			}

			.a_title7 {
				padding: 5px 23px;
				background: #3B68DF;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				margin-bottom: 10px;
			}

			.a_title8 {
				padding: 5px 23px;
				background: #F7F7F7;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				margin-bottom: 10px;
			}
		}

		.a_title9 {
			display: flex;
			justify-content: center;

			.a_title10 {
				width: 200px;
				height: 55px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
			}
		}
	}
</style>
