<template>
	<div>
		<myNav />
		<div class="b_content" style="height: 400px;">
			<!-- <div class="b_title1">法律声明及隐私权政策</div>
				<div class="b_title2">隐私权政策</div> -->
			<div class="b_title4">
				<span v-html="list.desc" class="g_title11"></span>
			</div>
		</div>

		<Footer />
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'

	export default {
		components: {
			Footer
		},
		data() {
			return {
				list: {}
			}
		},

		created() {
			this.myData(this.$route.query.id)
		},

		methods: {
			myData(e) {
				this.$axios({
					url: "article/getinfo",
					method: 'post',
					data: {
						id: e
					}
				}).then(res => {
					this.list = res.data.data
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.b_content {
		width: 1200px;
		background-color: #FFFFFF;
		margin: auto;
		padding: 20px;
		box-sizing: border-box;

		.b_title1 {
			font-size: 18px;
			font-weight: 600;
			color: #333333;
			text-align: center;
		}

		.b_title2 {
			font-size: 16px;
			margin: 20px 0px;
			font-weight: 600;
			color: #333333;
		}

		.b_title3 {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
		}

		.b_title4 {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			margin-top: 20px;
		}
	}
</style>
