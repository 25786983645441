import { render, staticRenderFns } from "./measurement.vue?vue&type=template&id=3a4eb941&scoped=true&"
import script from "./measurement.vue?vue&type=script&lang=js&"
export * from "./measurement.vue?vue&type=script&lang=js&"
import style0 from "./measurement.vue?vue&type=style&index=0&id=3a4eb941&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4eb941",
  null
  
)

export default component.exports