import { render, staticRenderFns } from "./oderVip.vue?vue&type=template&id=2c133869&scoped=true&"
import script from "./oderVip.vue?vue&type=script&lang=js&"
export * from "./oderVip.vue?vue&type=script&lang=js&"
import style0 from "./oderVip.vue?vue&type=style&index=0&id=2c133869&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c133869",
  null
  
)

export default component.exports