<template>
<div>
	<myNav />
	<!-- <login-pop/> --><!-- 跳转登录 -->
	<div class="a_content">
		<div class="a_all">
			<div class="a_title1">学校名称</div>
			<div class="a_title1">专业</div>
			<div class="a_title1">要求</div>
		</div>
		<div class="b_content" v-for="(item,index) in myList.data" :key="index">
			<div class="b_all">
				<div><img class="b_img" :src="item.logo" alt=""></div>
				<div class="b_title1">
					<div class="b_title2">{{item.title}}</div>
					<div class="b_title3">{{item.label}}</div>
					<div class="b_title4">
						<div v-show="item.f985==1" class="b_title6">985</div>
						<div v-show="item.f211==1" class="b_title7">211</div>
						<div v-show="item.dual_class==1" class="b_title8 ">双一流</div>
					</div>
				</div>
			</div>
			<div class="b_title9">{{item.major_title}}</div>
			<div class="b_title9">{{item.optional_advise}}</div>
		</div>
		<div class="search_all" style="display: flex;align-items: center;justify-content: center;padding: 50px 0px;">
		<el-pagination
		background
		layout="prev,pager,next"
		:total="myList.total"
		:page-size="10"
		:pager-count="7"
		:current-page="myList.current_page"
		@current-change="currentChange3"
		>
		</el-pagination>
		</div>
	</div>
	<Footer />
</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				list:[],
				myList:[],
				major_id:''
			}
		},
		activated() {
			if(!this.$store.state.userData.token) return
			this.major_id=this.$route.query.id
			this.dataAll(this.major_id)
		},
		methods: {
			//获取所有数据
			dataAll(e){
				this.$axios({
					url:'NewCneProvince/majorS',
					method:'post',
					data:{
						major_id:e,
						page: 1
					}
				}).then(res=>{
					this.myList=res.data.data
				})
			},
			//分页
			currentChange3(val){
				this.$axios({
					url:'NewCneProvince/majorS',
					method:'post',
					data:{
						major_id:this.major_id,
						page:val
					}
				}).then(res=>{
					this.myList=res.data.data
				})
			}
		}
	}
</script>
<style lang="less" scoped>
.a_content {
	width: 1200px;
	margin: 20px auto;
	background-color: #FFFFFF;
	.a_all{
		display: flex;
		align-items: center;
		.a_title1{
		width: 400px;
		height:40px;
		background: #F2F8FF;
		font-size:18px;
		font-weight: 400;
		color: #333333;
		letter-spacing:3px;
		display: flex;
		align-items: center;
		justify-content: center;
		}
	}
	.b_content{
		width: 1200px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #F5F5F5;
		.b_all{
		width: 400px;
		height: 148px;
		display: flex;
		align-items: center;
		justify-content:left;
		padding-left: 30px;
		box-sizing: border-box;
		.b_img{
			width:100px;
			height: 100px;
			border-radius: 50%;
			margin-right:20px;
			background-color: red;
		}
		.b_title1{
			.b_title2{
			font-size:18px;
			font-weight: 400;
			color: #333333;
			}
			.b_title3{
			font-size: 16px;
			font-weight: 400;
			color: #999999;
			margin: 10px 0px;
			}
			.b_title4{
				display: flex;
				align-items: center;
			.b_title5{
			width: 35px;
			height: 21px;
			background: #F0F5FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 400;
			color: #337CFF;
			}
			.b_title6{
			width: 32px;
			height: 21px;
			background: #FFF1F0;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 400;
			color: #FF4D4F;
			margin-left: 10px;
			}
			.b_title7{
			width: 28px;
			height: 21px;
			background: #DDF7E2;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 400;
			color: #3DB159;
			margin-left: 10px;
			}
			.b_title8{
			width: 47px;
			height: 21px;
			background: #F0F5FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 400;
			margin-left: 10px;
			color: #3B68DF;
			}
			}
		}
		}
		.b_title9{
			width:400px;
			font-size:18px;
			font-weight: 400;
			color: #666666;
			text-align: center;
		}
	}
}
</style>
