<template>
	<div>
		<myNav/>
		<!-- <login-pop/> --><!-- 跳转登录 -->
		<vipPop/>
		<div class="a_content">
			<div class="a_all">
				<div class="a_title1">学校名称</div>
				<div class="a_title1">专业名称</div>
				<div class="a_title1">选科要求</div>
			</div>
			<div class="a_title2" v-for="(item,index) in list" :key="index">
				<div class="a_title3">{{item.title}}</div>
				<div class="a_title3">{{item.major_title}}</div>
				<div class="a_title3">{{item.optional_advise}}</div>
			</div>
			<div class="search_all">
				<el-pagination
				background
				hide-on-single-page
				layout="prev,pager,next"
				:total="list.length"
				:page-size="4"
				:pager-count="7"
				:current-page="1"
				@current-change="currentChange"
				>
				</el-pagination>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				list:[],
				subject:''
			}
		},
		activated(){
			this.subject=this.$route.query.data
			this.dataAll(this.$route.query.data)
		},
		methods: {
			//获取所有数据
			dataAll(e){
				this.$axios({
					url:'NewCneProvince/couselection',
					method:'post',
					data:{
						page:1,
						subject:e
					}
				}).then(res=>{
					this.list=res.data.data
				})
			},
			//分页
			currentChange(e){
				this.$axios({
					url:'NewCneProvince/couselection',
					method:'post',
					data:{
						page:e,
						subject:this.subject
					}
				}).then(res=>{
					this.list=res.data.data
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
		.a_content{
			width: 1200px;
			margin: auto;
			.a_all{
				margin-top:20px;
				display: flex;
				align-items: center;
				height: 55px;
				background: #F2F8FF;
				width: 1200px;
				.a_title1{
					font-size:18px;
					font-weight: 400;
					color: #333333;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 400px;
				}
			}
			.search_all{
				height: 200px;
				background-color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.a_title2{
				display: flex;
				height: 60px;
				width: 1200px;
				background-color: #FFFFFF;
				border-bottom: 1px solid #F5F5F5;
				.a_title3{
				width: 400px;	
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size:16px;
				font-weight: 400;
				color: #666666;
				}
			}
		}
</style>
