<template>
	<div>
		<myNav />
		<!-- <loginPop /> -->
		<div class="tt_content">
			<div class="a_title1">
				<div v-for="(item,index) in listData" :key="index" :class="index1==index ? 'a_title2' : 'aa_title2'"
					@click="fen(index)">{{item.name}}</div>
			</div>
			<div v-if="index1==0">
				<div class="b_content">
					<div class="b_left">
						<div class="b_title1">
							<div class="b_title6">
								<div class="b_title2">查询位次</div>
								<div class="b_title3">
									<i class="iconfont icon-search"></i>
									<input class="b_title4" type="text" placeholder="请输入位次">
								</div>
								<div class="b_title5">马上查询</div>
							</div>
							<div><img class="b_imge" src="@/assets/c1.png" alt=""></div>
						</div>
						<div class="b_title9">
							<div class="b_title7">请选择年份</div>
							<div class="b_title8">
								<div class="b_title10" v-for="(item,index) in 3" :key="index">2019</div>
							</div>
							<div class="b_title7">请选择科目</div>
							<div class="b_title8">
								<div class="b_title10" v-for="(item,index) in 2" :key="index">理科</div>
							</div>
							<div class="b_title7">分数</div>
							<div><input class="b_title11" type="text" placeholder="请输入分数"></div>
							<div class="mySearch">开始查询</div>
						</div>
					</div>
					<div class="b_right">
						<div class="c_img">图表</div>
						<div class="c_content">
							<div class="c_all">
								<div class="c_title1">
									<i class="iconfont icon-weizhi"></i>
									<span class="c_title2">你的成绩</span>
								</div>
								<div class="c_title3">498</div>
								<div class="c_title4">你的成绩</div>
							</div>
							<div class="c_all">
								<div class="c_title1">
									<i class="iconfont icon-weizhi"></i>
									<span class="c_title2">你的成绩</span>
								</div>
								<div class="c_title3">498</div>
								<div class="c_title4">你的成绩</div>
							</div>
							<div class="c_all">
								<div class="c_title1">
									<i class="iconfont icon-weizhi"></i>
									<span class="c_title2">你的成绩</span>
								</div>
								<div class="c_title3">498</div>
								<div class="c_title4">你的成绩</div>
							</div>
						</div>
					</div>
				</div>
				<div style="margin: 40px; 0px">
					<span class="left_title1">年历分数换算</span>
					<span class="left_title2">（历年分数换算即历年相近次位所对应的分数，可用于比较历年高考的成绩竞争情况）</span>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div class="a_title9" v-for="(item,index) in 3" :key="index">
					<div class="a_title10" style="background-color: #FFFFFF;" v-for="(item,index) in tableData"
						:key="index">{{item.name}}</div>
				</div>
				<div style="margin: 40px; 0px">
					<span class="left_title1">年历分数换算</span>
					<span class="left_title2" style="color: #3B68DF;margin-left: 30px;">
						山东-2019-理科
					</span>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData1" :key="index">{{item.name}}</div>
				</div>
				<div class="a_title9" v-for="(item,index) in 3" :key="index">
					<div class="a_title10" style="background-color: #FFFFFF;" v-for="(item,index) in tableData1"
						:key="index">{{item.name}}</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="100" :page-size="4"
						:pager-count="7" :current-page="1" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
			<div v-if="index1==1">

			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				formInline: {
					name: ''
				},
				index1: 0,
				listData: [{
					name: '位次换算(文理)'
				}, {
					name: '位次换算(新高考)'
				}],
				titleData: [{
					name: '年历'
				}, {
					name: '位次区间'
				}, {
					name: '等位分'
				}],
				titleData1: [{
					name: '分数'
				}, {
					name: '同分人数'
				}, {
					name: '位次区间'
				}],
				tableData: [{
					name: '2020'
				}, {
					name: '16279-16761'
				}, {
					name: '607'
				}],
				tableData1: [{
					name: '690'
				}, {
					name: '50'
				}, {
					name: '1-50'
				}]
			};
		},
		activated() {
			//无需登录
			//if(!this.$store.state.userData.token) return 
			this.myData()
		},
		methods: {
			//获取所有数据
			myData() {
				// this.$axios({
				// 	url:'school/plan',
				// 	methods:'post',
				// 	data:{}
				// }).then(res=>{

				// })
			},
			//分数
			fen(e) {
				this.index1 = e
			},
			//分页
			// currentChange(e){
			// 	console.log(e)
			// }
		},
	}
</script>

<style lang="less" scoped>
	body {
		background-color: #FFFFFF !important;
	}

	.tt_content {
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;

		.a_title1 {
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;

			.a_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
				border-bottom: 2px solid #3B68DF;
				color: #3B68DF;
			}

			.aa_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
			}
		}

		.b_content {
			display: flex;
			margin-top: 27px;
			justify-content: space-between;

			.b_left {
				width: 397px;

				.b_title1 {
					width: 397px;
					height: 215px;
					background: #3B68DF;
					display: flex;
					align-items: center;
					justify-content: space-around;
					border-radius: 10px;
					padding: 0px 15px;
					box-sizing: border-box;

					.b_title6 {
						.b_title2 {
							font-size: 16px;
							font-weight: 400;
							line-height: 40px;
							color: #FFFFFF;
						}

						.b_title3 {
							display: flex;
							align-items: center;
							width: 200px;
							height: 31px;
							background: #5E84F4;
							border-radius: 9999px;
							padding-left: 10px;
							box-sizing: border-box;
							margin: 23px 0px;

							.iconfont {
								color: #FFFFFF;
							}

							.b_title4 {
								outline: none;
								border: none;
								background-color: #5E84F4;
								margin-left: 5px;
							}
						}

						.b_title5 {
							width: 96px;
							height: 31px;
							background: #F58235;
							box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.16);
							border-radius: 5px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 14px;
							font-weight: 400;
							color: #FFFFFF;
						}
					}

					.b_imge {
						width: 141px;
						height: 141px;
					}
				}

				.b_title9 {
					padding: 30px 20px;
					background-color: #EEEEEE;
					border-radius: 10px;
					margin-top: 10px;
					box-sizing: border-box;

					.b_title7 {
						font-size: 14px;
						font-weight: 400;
						color: #333333;
						margin-bottom: 19px;
					}

					.b_title11 {
						width: 357px;
						height: 40px;
						background: #FFFFFF;
						border: 1px solid #C9C9C9;
						border-radius: 5px;
						outline: none;
						padding-left: 20px;
						box-sizing: border-box;
					}

					.mySearch {
						width: 357px;
						height: 40px;
						background: #3B68DF;
						border-radius: 5px;
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 36px;
						font-weight: 400;
						color: #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.b_title8 {
						display: flex;
						flex-wrap: wrap;

						.b_title10 {
							width: 86px;
							height: 30px;
							background: #3B68DF;
							font-size: 14px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 5px;
							margin-right: 20px;
							margin-bottom: 20px;
						}
					}
				}
			}

			.b_right {
				.c_img {
					width: 793px;
					height: 441px;
					background: #EEEEEE;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 40px;
				}

				.c_content {
					padding: 0px 30px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-around;

					.c_all {
						.c_title1 {
							display: flex;
							align-items: center;
							margin-top: 30px;

							.iconfont {
								color: #3B68DF;
							}

							.c_title2 {
								font-size: 20px;
								font-weight: 400;
								color: #333333;
								margin-left: 10px;
							}
						}

						.c_title3 {
							font-size: 20px;
							font-weight: 600;
							color: #333333;
							text-align: center;
							margin: 30px 0px;
						}

						.c_title4 {
							font-size: 18px;
							font-weight: 400;
							color: #D9D9D9;
							text-align: center;
						}
					}
				}
			}
		}

		.left_title1 {
			font-size: 18px;
			font-weight: 400;
			color: #333333;
		}

		.left_title2 {
			font-size: 16px;
			font-weight: 400;
			color: #DDDDDD;
			margin-left: 5px;
		}

		.a_title9 {
			display: flex;
			justify-content: center;

			.a_title10 {
				width: 400px;
				height: 55px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
</style>
