<template>
	<div>
		<myNav />
		<!-- <loginPop/> -->
		<div class="tt_content">
			<div class="a_title1">
				<div v-for="(item,index) in listData" :key="index" :class="index1==index ? 'a_title2' : 'aa_title2'"
					@click="fen(index)">{{item.name}}</div>
			</div>
			<div v-if="index1==0">
				<div class="a_title5" style="margin-top: 40px;">
					<div class="a_title6">办学性质</div>
					<div :class="isCheck1==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.bxxz"
						:key="index" @click="isOk1(index,index,1)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">办学类型</div>
					<div :class="isCheck2==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.bf_type"
						:key="index" @click="isOk2(index,index,2)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck3==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.province"
							:key="index" @click="isOk3(index,index,3)">{{item}}</div>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck4==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.stype"
							:key="index" @click="isOk4(index,index,4)">{{item}}</div>
					</div>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :style="index==0 ? {'width':'220px'} : ''"
						:key="index">{{item.name}}</div>
				</div>
				<div v-loading="loading" :style="loading ? {'margin-top':'100px'} : ''">
					<div class="a_title9" v-for="(item,index) in tableData.list" :key="index">
						<div class="a_title10" style="width: 220px;font-size: 16px;">
							{{item.school_name ? item.school_name : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.province ? item.province : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.year ? item.year : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.pro_control ? item.pro_control : '-'}}
						</div>
						<div class="a_title10" style="font-size: 16px;">{{item.type ? item.type : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">
							{{item.admission_batch ? item.admission_batch : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">
							{{item.highest_score ? item.highest_score : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.lowest_order ? item.lowest_order : '-'}}
						</div>
						<div class="a_title10" style="font-size: 16px;">{{item.lowest_score ? item.lowest_score : '-'}}
						</div>
					</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="tableData.count"
						:page-size="20" :pager-count="7" :current-page="tableData.page" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
			<div v-if="index1==1">
				<div class="a_title5" style="margin-top: 40px;">
					<div class="a_title6">办学性质</div>
					<div :class="isCheck1==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.bxxz"
						:key="index" @click="isOk1(index,index,1)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">办学类型</div>
					<div :class="isCheck2==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.bf_type"
						:key="index" @click="isOk2(index,index,2)">{{item}}</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck3==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.province"
							:key="index" @click="isOk3(index,index,3)">{{item}}</div>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div style="width: 1000px;display: flex;flex-wrap: wrap;">
						<div :class="isCheck4==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in list.stype"
							:key="index" @click="isOk4(index,index,4)">{{item}}</div>
					</div>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :style="index==0 ? {'width':'220px'} : ''"
						:key="index">{{item.name}}</div>
				</div>
				<div v-loading="loading" :style="loading ? {'margin-top':'100px'} : ''">
					<div class="a_title9" v-for="(item,index) in tableData.list" :key="index">
						<div class="a_title10" style="width: 220px;font-size: 16px;">
							{{item.school_name ? item.school_name : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.province ? item.province : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.year ? item.year : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.pro_control ? item.pro_control : '-'}}
						</div>
						<div class="a_title10" style="font-size: 16px;">{{item.type ? item.type : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">
							{{item.admission_batch ? item.admission_batch : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">
							{{item.highest_score ? item.highest_score : '-'}}</div>
						<div class="a_title10" style="font-size: 16px;">{{item.lowest_order ? item.lowest_order : '-'}}
						</div>
						<div class="a_title10" style="font-size: 16px;">{{item.lowest_score ? item.lowest_score : '-'}}
						</div>
					</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="tableData.count"
						:page-size="20" :pager-count="7" :current-page="tableData.page"
						@current-change="currentChange1">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				datas: {
					school_type: '',
					type: '',
					is_pubilc: 0,
					province_id: '',
					is_new: 0,
					page: 1
				},
				list: [],
				formInline: {
					name: ''
				},
				index1: 0,
				isCheck1: 0,
				isCheck2: null,
				isCheck3: null,
				isCheck4: null,
				listData: [{
					name: '招生计划(文理)'
				}, {
					name: '招生计划(新高考)'
				}],
				titleData: [{
					name: '院校名称'
				}, {
					name: '地区'
				}, {
					name: '年份'
				}, {
					name: '省控线'
				}, {
					name: '招生类型'
				}, {
					name: '招生批次'
				}, {
					name: '最高分'
				}, {
					name: '最低位次'
				}, {
					name: '平均分'
				}],
				titleData1: [{
					name: '专业名称'
				}, {
					name: '专业代码'
				}, {
					name: '院校名称'
				}, {
					name: '专业代码'
				}, {
					name: '批次段'
				}, {
					name: '选择要求'
				}, {
					name: '学制'
				}, {
					name: '学费'
				}, {
					name: '计划人数'
				}],
				tableData: [],
				tableData1: [],
				loading: true
			};
		},
		activated() {
			//无需登录
			//if (!this.$store.state.userData.token) return
			this.myData()
		},
		methods: {
			//获取所有数据
			myData() {
				this.$axios({
					url: 'school/searchCondition',
					methods: 'post'
				}).then(res => {
					this.list = res.data.data
					this.loading = false
				})
				//招生计划查询数据
				this.$axios({
					url: 'school/get_provice_line',
					methods: 'post',
					data: this.datas
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//招生计划查询数据
			searchShcool(data, value) {
				value.page = 1
				this.tableData == []
				this.loading = true
				this.$axios({
					url: 'school/get_provice_line',
					methods: 'post',
					data: value
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//点击招生计划和新高考
			fen(e) {
				this.index1 = e
				this.datas.is_new = e
				this.datas.page = 1
				this.isCheck1 = 0
				this.isCheck2 = null
				this.isCheck3 = null
				this.isCheck4 = null
				this.tableData = []
				this.loading = true
				this.$axios({
					url: 'school/get_provice_line',
					methods: 'post',
					data: this.datas
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//选中状态
			isOk1(e, a) {
				this.isCheck1 = e
				this.datas.is_pubilc = a
				this.tableData = []
				this.loading = true
				this.searchShcool(a, this.datas)
			},
			//选中状态
			isOk2(e, a) {
				this.isCheck2 = e
				this.datas.school_type = a
				this.tableData = []
				this.loading = true
				this.searchShcool(a, this.datas)
			},
			//选中状态
			isOk3(e, a) {
				this.isCheck3 = e
				this.datas.province_id = a
				this.tableData = []
				this.loading = true
				this.searchShcool(a, this.datas)
			},
			//选中状态
			isOk4(e, a) {
				this.isCheck4 = e
				this.datas.type = a
				this.tableData = []
				this.loading = true
				this.searchShcool(a, this.datas)
			},
			//分页
			currentChange(e) {
				this.tableData = []
				this.loading = true
				this.datas.page = e
				this.$axios({
					url: 'school/get_provice_line',
					methods: 'post',
					data: this.datas
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			},
			//新高考分页
			currentChange1(e) {
				this.tableData = []
				this.loading = true
				this.datas.page = e
				this.$axios({
					url: 'school/get_provice_line',
					methods: 'post',
					data: this.datas
				}).then(res => {
					this.tableData = res.data.data
					this.loading = false
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.tt_content {
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;

		.a_title1 {
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;

			.a_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
				border-bottom: 2px solid #3B68DF;
				color: #3B68DF;
			}

			.aa_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
			}
		}

		.a_title5 {
			display: flex;
			align-items: center;
			margin-bottom: 23px;

			.a_title6 {
				margin-right: 40px;
				font-size: 20px;
				font-weight: 400;
				color: #333333;
				width: 100px;
				text-align: right;
			}

			.p_input {
				width: 156px;
				height: 40px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				border: none;
				outline: none;
				margin-right: 20px;
			}

			.sou {
				width: 86px;
				height: 35px;
				background: #3B68DF;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.a_title7:hover {
				cursor: pointer;
			}

			.a_title7 {
				padding: 5px 23px;
				background: #3B68DF;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				margin-top: 5px;
				margin-bottom: 5px;
			}

			.a_title8 {
				padding: 5px 23px;
				background: #F7F7F7;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				margin-top: 5px;
				margin-bottom: 5px;
			}

			.a_title8:hover {
				color: #FFFFFF;
				background-color: #3B68DF;
				cursor: pointer;
			}
		}

		.a_title9 {
			display: flex;
			justify-content: center;

			.a_title10 {
				width: 120px;
				height: 55px;
				background: #F7F7F7;
				border: 1px solid black;//#EBEBEB;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				font-weight: 400;
				//color: #333333;
			}
		}
	}
</style>
