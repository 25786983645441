<template>
<div>
	<myNav/>
	<!-- <login-pop/> -->
	<div class="content">
		<!-- <div><img class="a_content" src="@/assets/tu3.png" alt=""></div> -->
		<div class="a_all">
			<div class="a_title1">
				<div>
					<div class="a_title2">分数系统</div>
					<div class="a_title3">含有报考的五大数据，院校分数线、院校投档线、省控线、一分一段。输入分数，为考生筛选分数的院校以及专业，为考生在报考时提供数据参考。</div>
				</div>
				<div>
					<img class="p1" src="@/assets/c1.png" alt="">
				</div>
			</div>
			<div class="b_content">
				<div class="b_all" style="margin-left: 5px;" @click="detail(1)">
					<div class="b_title1">
						<i class="iconfont icon-yuanxiao"></i>
					</div>
					<div class="b_title2">专业分数线</div>
				</div>
				<div class="b_all" @click="detail(2)">
					<div class="b_title1">
						<i class="iconfont icon-zhuanye"></i>
					</div>
					<div class="b_title2">省控线</div>
				</div>
				<div class="b_all" @click="detail(3)">
					<div class="b_title1">
						<i class="iconfont icon-zhiyeweisheng"></i>
					</div>
					<div class="b_title2">院校投档线</div>
				</div>
				<div class="b_all" @click="detail(4)">
					<div class="b_title1">
						<i class="iconfont icon-shu"></i>
					</div>
					<div class="b_title2">位次换算</div>
				</div>
				<div class="b_all" style="margin-right: 0px;" @click="detail(5)">
					<div class="b_title1">
						<i class="iconfont icon-zhaoshengzige"></i>
					</div>
					<div class="b_title2">一分一段</div>
				</div>
			</div>
			
		</div>
		<div class="a_title4">
			<div>
				<img class="p2" src="@/assets/c2.png" alt="">
			</div>
			<div>
				<div class="a_title5">中学生生涯规划</div>
				<div class="a_title6">学习提升和志愿填报一站式升学服务综合平台。</div>
			</div>
		</div>
		<div class="a_all" style="margin-top: 40px;">
			<div class="a_title1">
				<div>
					<div class="a_title2">同专业院校分析</div>
					<div class="a_title3">根据学科门类选择拥有相同专业的大学进行对比，让学生在考前能够了解哪些院校开设了相同专业。</div>
				</div>
				<div>
					<img class="p1" style="width: 400px;" src="@/assets/c2.png" alt="">
				</div>
			</div>
			<div class="btn" @click="understand(1)">了解详情</div>
		</div>
		<div class="a_all" style="margin-top: 40px;">
			<div class="a_title1">
				<div>
					<div class="a_title2">同分考生去向</div>
					<div class="a_title3">考生同分去向数据能够很好的帮助考生分析往年同分的考生报考了哪些大学和专业，结合当年自身高考分数或高三模拟成绩提前查询往年同分走向。</div>
				</div>
				<div>
					<img style="width: 400px;" class="p1" src="@/assets/c3.png" alt="">
				</div>
			</div>
			<div class="btn">了解详情</div>
		</div>
	</div>
	<Footer/>
</div>
</template>
<script>
	import Footer from '@/components/footer'
export default {
components: {
	Footer
},
data(){
	return {

	};
	},
	methods:{
		detail(e){
			if(e==1){
				this.$router.push({path:'/volunteer/yLine'})
			}else if(e==2){
				this.$router.push({path:'/volunteer/professional'})
			}else if(e==3){
				this.$router.push({path:'/volunteer/archives'})
			}else if(e==4){
				this.$router.push({path:'/volunteer/conversion'})
			}else{
				this.$router.push({path:'/volunteer/admissions'})
			}
		},
		//了解详情
		understand(){
			this.$router.push({path:'/Analysis'})
		}
	},
}

</script>

<style lang="less" scoped>
	.content{
		.a_content{
			width: 1200px;
			height: 471px;
			background: rgba(0, 0, 0, 0);
			object-fit:cover;
			margin: 20px 0px 40px 0px;
		}
		.a_all{
			width: 1200px;
			background: #F2F7FE;
			padding: 69px 0px 38px 0px;
			box-sizing: border-box;
			.btn:hover{
				color: #F58235;
				background-color: #5E84F4;
			}
			.btn{
				width: 200px;
				height: 40px;
				background: #337CFF;
				border-radius: 2px;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 40px;
			}
			.a_title1{
				display: flex;
			.a_title2{
				font-size:24px;
				font-weight: 400;
				color: #333333;
				margin-bottom: 26px;
				margin-left: 40px;
			}	
			.a_title3{
				font-size: 26px;
				font-weight: 400;
				color: #666666;
				margin-left: 40px;
			}
			.p1{
				width: 300px;
				height: 300px;
				margin-top: 40px;
			}
			}
			.b_content{
				display: flex;
				flex-wrap: wrap;
				.b_all{
					margin-right: 120px;
					.b_title1{
						width: 140px;
						height: 140px;
						background: #337CFF;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						.iconfont:hover{
							color: #F58235;
						}
						.iconfont{
							color: #FFFFFF;
							font-size: 60px;
						}
					}
					.b_title2:hover{
						color: #F58235;
					}
					.b_title2{
						margin-top: 20px;
						font-size: 26px;
						font-weight: 400;
						color: #000000;
						text-align: center;
					}
				}
			}
		}
		.a_title4{
			width: 1200px;
			background: #F0F6FE;
			display: flex;
			margin-top: 40px;
			padding: 69px 0px 0px 0px;
			.p2{
				width: 700px;
				height: 300px;
			}
			.a_title5{
				font-size:24px;
				font-weight: 400;
				color: #333333;
			}
			.a_title6{
				margin-top: 26px;
				font-size: 20px;
				font-weight: 400;
				color: #666666;
			}
		}
	}
</style>
