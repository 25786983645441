<template>
	<div id="content">
		<div class="l_content">
			<div class="l_all">
				<div class="l_title7">{{myData.title? myData.title.remarks : ''}}</div>
			</div>
			<div class="l_title1">
				<!-- <i class="iconfont icon-shuoming"></i><span class="l_title2">小程序</span> -->
				<i class="iconfont icon-weixin1" @mouseover="enters()" @mouseleave="leaveTo()"></i><span class="l_title2" @mouseover="enters()" @mouseleave="leaveTo()">微信小程序</span>
				<i class="iconfont icon-shouji1"></i><span class="l_title2" @click="download()">APP下载</span>
				<!-- <i class="iconfont icon-tuandui"></i> -->
				<span v-if="!token" style="margin-right:0px" class="l_title2"><router-link style="text-decoration:none;" to="/Login">登录</router-link></span>
				<span v-if="!token" style="margin-right:0px" class="l_title2"><router-link style="text-decoration:none;" to="/Register">注册</router-link></span>
				<!-- <span v-if="token"  style="margin-right:0px" class="l_title2">{{userName}}</span>
				<span @click="exit()" class="login" v-if="token">退出</span> -->
				<div v-show="shows"><img class="xiao_code" :src="myData.wxapplets? myData.wxapplets.img : ''" alt=""></div>
			</div>
		</div>
		<navTop/>
		<div class="a_content">
		<div v-for="(item,index) in indexData" :key="index" class="a_all" :class="checks==index ? 'a1_all' : ''" @mouseover="mouseOver(index)" @mouseleave="leave(index)" @click="isCheck(index)" >
			<div :class="checks==index ? 'a_title1' : 'a_title3'">
				<router-link style="color: #FFFFFF;text-decoration:none;" :to="item.myRoute">{{item.name}}</router-link>
				<div class="k_content" @mouseover="mouseOver(2)" @mouseleave="leave(2)" v-show="isShow">
					<div class="b_title1"  v-for="(i,mIndex) in item.list1" :key="mIndex" @mouseover="mouseOver1(mIndex)" @mouseleave="leave1(mIndex)" :style="isBg==mIndex ? {background:'#337CFF',color:'#FFFFFF'} : mIndex==2 ? {'border-bottom':'none','border-bottom-left-radius': '30px','border-bottom-right-radius': '30px'} : ''">
						<router-link style="text-decoration:none;color:#333333;" :style="isBg==mIndex ? {color:'#FFFFFF'} : ''" :to="i.myRoute">{{i.title}}</router-link>
					</div>
				</div>
				<div class="n_content" @mouseover="mouseOver6(3)" @mouseleave="leave6(3)" v-show="isShow3">
					<div class="b_title1"  v-for="(i,mIndex) in item.list4" :key="mIndex" @mouseover="mouseOver1(mIndex)" @mouseleave="leave1(mIndex)" :style="isBg==mIndex ? {background:'#337CFF',color:'#FFFFFF'} : mIndex==2 ? {'border-bottom':'none','border-bottom-left-radius': '30px','border-bottom-right-radius': '30px'} : ''">
						<router-link style="text-decoration:none;color:#333333;" :style="isBg==mIndex ? {color:'#FFFFFF'} : ''" :to="i.myRoute">{{i.title}}</router-link>
					</div>
				</div>
				<div class="s_content" @mouseover="mouseOver3(4)" @mouseleave="leave3(4)" v-show="isShow2">
					<div class="b_title1"  v-for="(i,mIndex) in item.list3" :key="mIndex" @mouseover="mouseOver1(mIndex)" @mouseleave="leave1(mIndex)" :style="isBg==mIndex ? {background:'#337CFF',color:'#FFFFFF'} : mIndex==2 ? {'border-bottom':'none','border-bottom-left-radius': '30px','border-bottom-right-radius': '30px'} : ''">
						<router-link style="text-decoration:none;color:#333333;" :style="isBg==mIndex ? {color:'#FFFFFF'} : ''" :to="i.myRoute">{{i.title}}</router-link>
					</div>
				</div>
				<div class="j_content" @mouseover="mouseOver2(5)" @mouseleave="leave2(5)" v-show="isShow1">
					<div class="c_title1"  v-for="(i,mIndex) in item.list2" :key="mIndex" @mouseover="mouseOver1(mIndex)" @mouseleave="leave1(mIndex)" :style="isBg==mIndex ? {background:'#337CFF',color:'#FFFFFF'} : ' '">
						<router-link style="text-decoration:none;color:#333333;" :style="isBg==mIndex ? {color:'#FFFFFF'} : ''" :to="i.myRoute">{{i.title}}</router-link>
					</div>
				</div>
				<div class="w_content" @mouseover="mouseOver9(9)" @mouseleave="leave9(9)" v-show="isShow9">
					<div class="b_title1"  v-for="(i,mIndex) in item.list5" :key="mIndex" @mouseover="mouseOver1(mIndex)" @mouseleave="leave1(mIndex)" :style="isBg==mIndex ? {background:'#337CFF',color:'#FFFFFF'} : mIndex==2 ? {'border-bottom':'none','border-bottom-left-radius': '30px','border-bottom-right-radius': '30px'} : ''">
						<!-- <router-link style="text-decoration:none;color:#333333;" :style="isBg==mIndex ? {color:'#FFFFFF'} : ''" :to=" item.state ? i.myRoute : ''">{{i.title}}</router-link> -->
						<div style="text-decoration:none;color:#333333;" :style="isBg==mIndex ? {color:'#FFFFFF'} : ''" @click="tiaozhuan(i)">{{i.title}}</div>
					</div>
				</div>
			</div>
			<i class="iconfont icon-xiala2" v-if="index!=0&&index!=7&&index!=4"></i>
		</div>
		</div>
	</div>
</template>

<script>
import navTop from '@/components/navTop'
	export default {
		components:{
			navTop
		},
		props:['loginValue'],
		data() {
			return {
				myData:{},
				shows:false,
				userName:'匿名用户',
				token:'',
				checks:1,
				isShow:false,
				isShow1:false,
				isShow2:false,
				isShow3:false,
				isShow9:false,
				isBg:'',
				indexData:[{name:'首页',myRoute:'/index'},
							{name:'新高考',myRoute:'/new/Colleges',list4:[{title:'查大学',myRoute:'/new/searchPro'},{title:'查专业',myRoute:'/new/searchFesic'},{title:'完善成绩',myRoute:'/new/newColleges'}]},
							{name:'快捷查询',myRoute:'/Universities',list1:[{title:'院校查询',myRoute:'/Universities'},{title:'专业查询',myRoute:'/Zhuanye'}]},
							{name:'历年分数线',myRoute:'/volunteer/voulenter',list3:[{title:'专业分数线',myRoute:'/volunteer/yLine'},{title:'省控线',myRoute:'/volunteer/professional'},{title:'一分一段',myRoute:'/volunteer/admissions'}]},
							{name:'学生测评',myRoute:'/Measurement'},
							{name:'艺术类系统',myRoute:'/ArtIndex',list2:[{title:'院校查询',myRoute:'/ArtSchool'},{title:'专业查询',myRoute:'/professionalArt'}]},
							{name:'海外留学',myRoute:'/Enter'},
							{name:'专家咨询',myRoute:'/contact'},
							{name:'个人中心',myRoute:'/About',list5:[{title:'登录',myRoute:'/Login',state:1},{title:'退出',myRoute:'/Login',state:2}]}]
			}
		},
		activated(){
			this.checks=0
			this.isShow=false
			this.isShow1=false
			this.isShow2=false
			this.isShow3=false
			this.isShow9=false
			this.$axios({
				url:'Pcimg/pcxxx',
				methods:'post'
			}).then(res=>{
				this.myData=res.data.data	
			})
			if (this.$store.state.userData.token) {
				if(this.$store.state.userData.userInfo.member_name){
					this.indexData[8].list5[0].title=this.$store.state.userData.userInfo.member_name
					this.indexData[8].list5[1].title='退出'
				}else{
					this.indexData[8].list5[0].title='匿名用户'
					this.indexData[8].list5[1].title='退出'
				}
			}else{
				this.indexData[8].list5[1].title='已退出登录'
			}
		},
		methods: {
			//个人中心下拉列表跳转
			tiaozhuan(e){
				if(e.state==1){
					if (this.$store.state.userData.token) {
						if(this.$store.state.userData.userInfo.member_name){
							this.indexData[8].list5[0].title=this.$store.state.userData.userInfo.member_name
						}else{
							this.indexData[8].list5[0].title='匿名用户'
						}
					}else{
						this.$router.push({path:'/Login'})
						this.indexData[8].list5[0].title='登录'
					}
				}else if(e.state==2){
					if (this.$store.state.userData.token) {
						this.indexData[8].list5[1].title='退出'
						this.$store.commit('userInfo',"")
						this.$store.commit('getMember',"")
						this.$store.commit('vipData',"")
						this.$router.push({path:'/Login'})
						this.$message({
							message:'退出登录成功',
							type:''
						})
					}else{
						this.indexData[8].list5[1].title='已退出登录'
					}
				}
			},
			//鼠标移入显示二维码
			enters(){
				this.shows=true
			},
			//鼠标移出影藏二维码
			leaveTo(){
				this.shows=false
			},
			//鼠标移入
			mouseOver(e){
				this.checks = e
				if(this.checks==2){
					this.isShow=true
				}else if(this.checks==5){
					this.isShow1=true
				}else if(this.checks==3){
					this.isShow2=true
				}else if(this.checks==1){
					this.isShow3=true
				}else if(this.checks==8){
					this.isShow9=true
				}
			},
            leave(){
              if(this.checks==2){
					this.isShow=false
				}else if(this.checks==5){
					this.isShow1=false
				}else if(this.checks==3){
					this.isShow2=false
				}else if(this.checks==1){
					this.isShow3=false
				}else if(this.checks==8){
					this.isShow9=false
				}
			},
			//鼠标移入
			mouseOver2(e){
				this.checks = e
				if(this.checks==5){
					this.isShow1=true
				}
			},
            leave2(){
              if(this.checks==5){
					this.isShow1=false
				}
			},
			//鼠标移入
			mouseOver3(e){
				this.checks = e
				if(this.checks==4){
					this.isShow2=true
				}
			},
			leave3(){
				if(this.checks==4){
					this.isShow2=false
				}
			},
			//鼠标移入
			mouseOver6(e){
				this.checks = e
				if(this.checks==3){
					this.isShow3=true
				}
			},
			leave6(){
				if(this.checks==3){
					this.isShow3=false
				}
			},
			//鼠标移入个人中心
			mouseOver9(e){
				this.checks = e
				if(this.checks==9){
					this.isShow9=true
				}
			},
			leave9(){
				if(this.checks==9){
					this.isShow9=false
				}
			},
			mouseOver1(e){
                this.isBg=e
			},
			leave1(){
               this.isBg=''
			},
			//选中时的状态
			isCheck(e) {
				this.checks = e
			},
			//app下载页面
			download(){
				this.$router.push({path:'/download/appDownload'})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	.router-link{
	text-decoration:none;
	color: #FFFFFF;
	}
	#content {
		.l_content{
			margin: auto;
			width:1200px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom:25px;
			.l_all{
				display: flex;
				align-items: center;
				.l_title6{
					font-size: 16px;
					font-weight: 400;
					color: #F94D28;
					margin-right: 20px;
				}
				.l_title7{
					height: 33px;
					display: flex;
					align-items: center;
					font-size: 14px;
					font-weight: 400;
					color: #999999;
				}
			}
			.l_title1{
				display: flex;
				align-items: center;
				position: relative;
				.xiao_code{
					position: absolute;
					height:120px;
					width: 120px;
					left:-14px;
					top: 40px;
					z-index: 9999;
				}
				.login{
					margin-left:50px;
					width:50px;
					height:20px;
					background:#FEA52D;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 3px;
					font-size:14px;
				}
				.login:hover{
					color: #F58235;
					background-color:#0000EE;
				}
				.icon-shuoming{
					color: #2BDF6A;
					font-size: 20px;
				}
				.icon-weixin1{
					color: #69C028;
					font-size: 20px;
				}
				.icon-shouji1{
					color: #1872E9;
					font-size: 20px;
				}
				.icon-tuandui{
					color: #F94D28;
					font-size: 20px;
				}
				.l_title2:hover{
					cursor: pointer;
					color: #F58235;
				}
				.l_title2{
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				margin-left: 5px;
				margin-right: 21px;
				}
			}
		}
		.n_content{
			position: absolute;
			width: 135px;
			background-color:#f8f8f8;
			left:-33px;
			top:38px;
			z-index: 999;
			.b_title1{
			width: 135px;
			height: 40px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #666666;
			}
		}
		.w_content{
			position: absolute;
			width: 135px;
			background-color:#f8f8f8;
			left:-24px;
			top:38px;
			z-index: 999;
			.b_title1{
			width: 135px;
			height: 40px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #666666;
			}
		}
		.s_content{
			position: absolute;
			width: 135px;
			background-color:#f8f8f8;
			left:-25px;
			top:38px;
			z-index: 999;
			.b_title1{
			width: 135px;
			height: 40px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #666666;
			}
		}
		.k_content{
			position: absolute;
			width: 135px;
			background-color:#f8f8f8;
			right:-46px;
			top:38px;
			z-index: 999;
			.b_title1{
			width: 135px;
			height: 40px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #666666;
			}
		}
		.j_content{
			position: absolute;
			width: 151px;
			background-color:#FFFFFF;
			right:-46px;
			top:38px;
			z-index: 999;
			.c_title1{
			width: 151px;
			height: 40px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #666666;
			// border-bottom: 1px solid #F5F5F5;
			}
		}
		.a_content {
			width: 100%;
			background: #4E6EF2;
			height: 55px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			//
			.a1_all {
				padding: 0px 14px;
				box-sizing: border-box;
				position: relative;
				height: 55px;
				display: flex;
				justify-content: center;
				align-items: center;
				//background-color: #337CFF;
			}
			.a_all:hover{
				color: #FFFFFF;
				background-color:#337CFF;
			}
			.a_all {
				padding: 0px 25px;
				box-sizing: border-box;
				position: relative;
				height: 55px;
				display: flex;
				justify-content:space-around;
				align-items: center;

				.iconfont {
					color: #FFFFFF;
					margin-left: 5px;
				}

				.a_title1 {
					font-size: 16px;
					font-weight: 600;
					color: #FFFFFF;
					position: relative;
				}

				.a_title3 {
					font-size: 16px;
					font-weight: 400;
					color: #FFFFFF;
					position: relative;
				}

				.a_title2 {
					position: absolute;
					bottom: 0px;
					width: 33px;
					height: 4px;
					background: #FFFFFF;
					border-radius: 3px;
				}
			}
		}
	}
</style>
