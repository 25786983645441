<template>
	<div>
		<myNav/>
		<login-pop/> <!-- 未登录弹出 -->
		<div class="content">
			<div class="h_content">
               <div class="a_title1">确定订单信息</div>
                <div style="margin-top: 20px">
                    <el-select v-model="province.index" placeholder="请选择" @change="pro_Change">
                        <el-option
                                v-for="(item,index) in province.list"
                                :key="index"
                                :label="item.areaName"
                                :value="index">
                        </el-option>
                    </el-select>
                    <el-select v-model="citys.index" style="margin-left: 20px" placeholder="请选择">
                        <el-option
                                v-for="(item,index) in citys.list"
                                :key="index"
                                :label="item.areaName"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
               <!-- <div class="a_all">
                   <div class="a_title2">会员卡号</div>
                   <div><input class="a_title3" type="text" placeholder="请输入卡号（请谨慎激活会员卡，一张会员卡仅能激活一次）"></div>
               </div> -->
               <div class="a_all">
                   <div class="a_title2">会员卡密</div>
                   <div><input class="a_title3" type="text" v-model="code" placeholder="请输入密码"></div>
               </div>
                <div class="a_title4">*请谨慎激活会员卡，一张会员卡仅能激活一次</div>
                <div class="a_title5">
                    <div class="a_title6" @click="commit()">确 定</div>
                    <div class="a_title7">取 消</div>
                </div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		props: ['myValue'],
		data() {
			return {
                province: {list: [],index: 0},
                citys: {list: [],index: 0},
                code: ''
			}
		},
		activated(){
            this.$axios({
                url: 'area/index'
            }).then((res) => {
                if (res.data.status == 200){
                    this.province.list = res.data.data
                    this.city()
                }
            })
		},
		methods: {
            city(){
                /* 市 */
                this.$axios({
                    url: 'area/city',
                    data:{pro_id:this.province.list[this.province.index].areaId}
                }).then((res) => {
                    if (res.status == 200){
                        this.citys.list = res.data.data
                    }
                    if (this.isShow) this.isShow = false
                })
            },
            pro_Change () {
                this.city()
            },
            commit () {
                if (!this.code) return this.$message('请输入卡密')
                this.$axios({
                    url: 'member/activation',
                    data:{
                        serial_number:this.code,
                        province:this.province.list[this.province.index].areaId,
                        city:this.citys.list[this.citys.index].areaId,
                    }
                }).then((res) => {
                    this.$message(res.data.msg)
                    if (res.data.status) {
                        this.$router.push('/index')
                    }

                })
            }
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.h_content{
			width:1200px;
			height: 1029px;
			background: #FFFFFF;
			padding:20px;
			box-sizing: border-box;
           .a_title1{
            font-size: 20px;
            font-weight: 400;
            color: #666666;
           }
           .a_all{
             display: flex;
             align-items:center;
             margin: 30px 0px;
             .a_title2{
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-right: 20px;
             }
             .a_title3{
               width: 600px;
                height: 50px;
                background: #F5F5F5;
                border-radius: 4px;
                border:none;
                outline:none;
				padding-left: 30px;
				box-sizing: border-box;
             }
           }
           .a_title4{
            font-size: 12px;
            font-weight: 400;
            color: #F94D28;
            margin-bottom: 60px;
           }
           .a_title5{
               display: flex;
               justify-content: center;
				.a_title6:hover{
				cursor: pointer;
				}
				.a_title7:hover{
				cursor: pointer;
				}
               .a_title6{
                   width: 140px;
                    height: 50px;
                    background: #337CFF;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 100px;
               }
               .a_title7{
                   width: 140px;
                    height: 50px;
                    background: #FFFFFF;
                    border: 1px solid #CCCCCC;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
               }
           }
		}
	}
</style>
