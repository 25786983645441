import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
// 创建对象，借助浏览器缓存，存入localStorage
	const vuexLocal = new VuexPersist({
		storage: window.localStorage  // 可选，sessionStorage/indexDB
	})
//挂载Vuex
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
		isCheck:'',
		userData:{},
		artData:'',
		vip:'',
		form:{},
		shcoolDetail:{},
		memberData:{},
		vipData:{},
		ceshiIndex:{},//测评首页数据，
		resultData:[],//测评结果数据，
		shcoolData:[]//获取学校数据
    },
    mutations: {
		myCheck(state,a){
			state.isCheck=a
		},
		userInfo(state,data){
			state.userData=data
		},
		artCheck(state,e){
			state.artCheck=e
		},
		//开通vip
		loginVip(state,e){
			state.vip=e
		},
		//填报数据
		fillData(state,data){
			state.form=data
		},
		//学校详情数据
		detail(state,data){
			state.shcoolDetail= data
		},
		//保存member表里的数据
		getMember(state,data){
			state.memberData=data
		},
		getVipData(state,data){
			state.vipData=data
		},
		//测评首页数据
		textIndex(state,data){
			state.ceshiIndex=data
		},
		//测试结果数据
		testData(state,data){
			state.resultData=data
		},
		//获取所有学校数据
		shcoolAllData(state,data){
			state.shcoolData=data
		}
    },
    actions: {
        getToken ({ state }) {
            return state.token
        },
		// getDetail({ state }, key) {
		// 	return state[key]
		// },
		getCheck({ state }){
			return state.isCheck
		}
    },
	plugins: [vuexLocal.plugin]
})

export default store
