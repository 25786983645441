<template>
<div>
	<myNav/>
	<loginPop/>
	<div class="content">
	<div class="a_content">
	<div class="a_left">
	<div style="margin:auto"><img class="a_img" src="@/assets/tu3.png" alt=""></div>
	<div class="f_all">
		<div class="f_title1">{{memberList.card_title ? memberList.card_title : '未开通VIP'}}</div>
		<div class="f_title2" @click="isOk(4)">升级服务</div>
	</div>
	<div class="a_title1">距离高考还有：<span style="color: red;">{{timeDay ? timeDay : '0'}}天</span></div>
	<div @click="isOk(index)" :class="isCheck==index ? 'a_title2' : 'a_title3'" v-for="(item,index) in leftData" :key="index">{{item.name}}</div>
	</div>
	<div class="a_right">
	<div v-if="isCheck==4">
		<div class="g_content">
			<div class="g_title1">升级会员卡</div>
			<div class="g_all">
				<div class="g_title2">
					<div @click="myisDetail(item,index)" class="g_title8" v-for="(item,index) in vipList" :key="index">
						<div class="g_title9" :style="{'background':item.background}">
							<div>
								<div class="g_title10">VIP{{item.name}}</div>
								<div class="g_title11">VIP总时间：{{item.days}}天</div>
								<div class="g_title12">价格：¥ {{item.money}}元</div>
							</div>
							<div><img class="g1_img" src="@/assets/v1.png" alt=""></div>
						</div>
						<div class="yuan" :style="myIsCheck!=index ? {'background':'#FFFFFF'} : ''"></div>
					</div>
				</div>
				<div v-if="myIsCheck!=null">
					<div class="g_title3">商品名称：{{vip1.name}}</div>
					<!-- <div class="g_title4">原价：¥{{vip1.money}}</div> -->
					<div class="g_title5" style="margin-top: 14px;">
						<span class="g_title6">价格：</span>
						<span class="g_title7">¥{{vip1.money}}</span>
					</div>
				</div>
			</div>
			<div>
				<div class="g_title13" @click="payFor()">立即购买</div>
				<!-- <div>选择激活</div> -->
			</div>
		</div>
	</div>
	<div v-if="isCheck==0" style="padding-right: 200px;">
	<el-form ref="form" label-width="100px" class="form" label-position="left">
							<!-- <el-form-item label="上传头像：">
								<el-upload
									class="avatar-uploader"
									action=""
									:http-request="updateImg"
									:show-file-list="false"
									:on-success="handleAvatarSuccess">
									<img v-if="form.avatar" :src="form.avatar" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-form-item> -->
	<el-form-item label="您的姓名：">
	<el-input v-model="form.member_name" placeholder="请输入您的姓名" style="width: 100%"></el-input>
	</el-form-item>
	<el-form-item label="性别选择：">
	<el-select v-model="form.gender" placeholder="请选择性别" style="width: 100%" @change="genderChange">
		<el-option label="男" :value="2"></el-option>
		<el-option label="女" :value="1"></el-option>
	</el-select>
	</el-form-item>
	<el-form-item label="高考年份：">
	<el-select v-model="form.nemt_year" placeholder="请选择报考年份" style="width: 100%">
	<el-option :label="item" :value="item" v-for="(item,index) in years" :key="index"></el-option>
	</el-select>
	</el-form-item>
	<el-form-item label="高考省份：">
	<el-select v-model="form.province" placeholder="请选择报考省份" style="width: 100%">
	<el-option v-for="(item,index) in province_list" :key="index" :label="item.title" :value="item.id"></el-option>
	</el-select>
	</el-form-item>
	<el-form-item label="考试类型：">
	<el-select v-model="form.exam_type" placeholder="请选择考试类型" style="width: 100%">
	<el-option label="统考" :value="1"></el-option>
	<el-option label="艺考" :value="2"></el-option>
	</el-select>
	</el-form-item>
	<el-form-item>
	<el-button type="primary" @click="onSubmit">提交</el-button>
	</el-form-item>
	</el-form>
	</div>
	<div v-if="isCheck == 1">
	<div class="b_content">
	<div class="b_right">
	<div class="b_title1" @click="isOk (1)">备选院校</div>
	</div>
	<div class="b_next" v-for="(item,index) in spare.data" :key="index" @click="isDetail(item)">
	<div><img class="b_img" :src="item.school.logo" alt=""></div>
	<div class="b_title1">
	<div class="b_title2">{{item.school.title}}</div>
	<div class="b_title3">
	<span style="margin: 0 5px;"
	v-for="(item,index) in item.school.label" :key="index" >{{item}}</span>
	</div>
	<div class="b_title4">
	<div class="b_title5" v-if="item.school.is_pubilc == 1">公办</div>
	<div class="b_title6" v-if="item.school.f985 == 1">985</div>
	<div class="b_title7" v-if="item.school.f211 == 1">211</div>
	<div class="b_title5" v-if="item.school.dual_class_name">双一流</div>
	</div>
	</div>
	</div>
	<div class="search_all" style="display: flex;align-items: center;justify-content: center;margin-top: 50px;">
	<el-pagination
	background
	layout="prev,pager,next"
	:total="spare.total"
	:page-size="4"
	:pager-count="7"
	:current-page="spare.page"
	@current-change="currentChange3"
	>
	</el-pagination>
	</div>
	</div>
	</div>
	<div v-if="isCheck == 2" @click="isOk(2)">
	<div class="c_next">
	<div class="c_title1">我的志愿表</div>
	<div class="c_title2" v-for="(item,index) in volunteer.list" :key="index">
	<div class="c_title3">{{item.title}}</div>
	<div class="c_title4">
	<div class="c_title5">创建时间：{{item.create_time}}</div>
	<div class="c_title5">学科等级：{{item.batch}}</div>
	</div>
	</div>
	</div>
	</div>
	<div v-if="isCheck == 3">
	<div class="c_next">
	<div class="c_title1" @click="isOk(3)">专业评测分析报告</div>
	<div class="c_title2" v-for="(item,index) in evaluation.data" :key="index" @click="testDetail(item)">
	<div class="c_title3">{{item.title}}</div>
	<div class="c_title4">
	<div class="c_title5">创建时间：{{item.time}}</div>
	<!-- <div class="c_title5">学科等级：{{item.batch}}</div> -->
	</div>
	</div>
	<div class="search_all" style="margin-top:50px">
	<el-pagination
	background
	layout="prev,pager,next"
	:total="evaluation.counts"
	:page-size="5"
	:pager-count="7"
	:current-page="evaluation.page"
	@current-change="currentChange1"
	>
	</el-pagination>
	</div>
	</div>
	</div>
	<!-- <div v-if="isCheck==2||isCheck==3||isCheck==5||isCheck==6||isCheck==7||isCheck==8">
	<div class="c_next">
	<div class="c_title1">{{isCheck==2 ? '我关注的专业' : isCheck==3 ?  '我关注的职业' : isCheck==4 ? '我的志愿表' : isCheck==5 ? '文理科分析报告' : isCheck==6 ? '新高考选科报告' : isCheck==7 ? '专业评测分析报告' : '其他分析报告'}}</div>
	<div class="c_title2" v-for="(item,index) in 4" :key="index">
	<div class="c_title3">{{isCheck==2 ? '宗教学' : isCheck==3 ?  '电气工程技术人员' : isCheck==4 ? '志愿表' : isCheck==5 ? '文理科分析报告' : isCheck==6 ? '新高考选科报告' : isCheck==7 ? '专业评测分析报告' : 'PDP测试报告'}}</div>
	<div class="c_title4">
	<div class="c_title5">{{isCheck==2 ? '授予学位：哲学学士' : isCheck==3 ?  '对口专业：机械电子工程' : isCheck==4 ? '创建时间：2021-01-12 13:12:17' : isCheck==4 || isCheck==7 || isCheck==8 ? '创建时间：2021-01-12 13:12:17' : '创建时间：2021-01-12 13:12:17'}}</div>
	<div class="c_title5" v-if="(isCheck==2||isCheck==3)">{{isCheck==2 ? '专业类别：哲学》哲学类》宗教学' : '职业类别：电气/电力》电气/电力》电气工程技术人员'}}</div>
	</div>
	</div>
	</div>
	</div> -->
	<div v-if="isCheck==9">
	<div>
	<el-table
	:data="tableData"
	border
	style="width: 100%"
	>
	<el-table-column
	prop="date"
	label="商品名称"
	width="180">
	</el-table-column>
	<el-table-column
	prop="name"
	label="价格"
	width="180">
	</el-table-column>
	<el-table-column
	prop="address"
	label="购买渠道"
	width="180">
	</el-table-column>
	<el-table-column
	prop="address"
	label="操作"
	width="180">
	</el-table-column>
	</el-table>
	</div>
	</div>
	</div>
	</div>
	</div>
	<Footer/>
</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	// import common from "../common/common";
	export default {
		components: {
			Footer
		},
		data() {
			return {
				timeDay:'',
				myValue:1,
				isCheck:0,
				form: {
					province:37,
					user_id:'',
					member_name: ''
				},
				// 城市列表
				province_list: [],
				years: [],
				auth_cos: {},
				leftData:[{name:'个人资料'},{name:'我的备选'},/* {name:'我关注的专业'},{name:'我关注的职业'}, */{name:'我的志愿表'},
				/* {name:'文理科分析报告'},{name:'新高考选科报告'}, */{name:'专业评测分析报告'},/* {name:'其他报告'},{name:'我的订单'} */{name:'我的会员卡'}],
				// 我的备选
				spare: {
					page: 1
				},
				// 志愿表
				volunteer: {},
				// 评测
				evaluation: [],
				vipList:[],
				vip1:{},
				myIsCheck:null,
				memberList:{}
			}
		},
		activated() {
			this.memberList=this.$store.state.memberData
			if(!this.$store.state.userData.token) return
			this.form.user_id = this.$store.state.userData.user_id ? this.$store.state.userData.user_id : this.$store.state.userData.userInfo.user_id
			// 获取未来5年
			let date = new Date()
			let current_year = date.getFullYear()
			for (let i = current_year; i < current_year + 5; i++) {
				this.years.push(i)
			}
			// 获取城市列表
			this.$axios({url:'pro/school',method: 'POST',}).then(res=>{
				if(res.data.status==999) return this.$router.push({path:'/Login'})
				this.province_list = res.data.data
			})
			// 获取用户信息
			this.$axios({
				url:'member/getMemberInfo',
				method: 'POST',
				data:{member_id: this.form.user_id,
			}}).then(res=>{
				if(res.data.status==999) return this.$router.push({path:'/Login'})
				res.data.data.user_id = this.form.user_id
				this.form = res.data.data
			})
			// this.auth_cos = common.getAuth()
			this.timeData()
		},
		methods: {
			//跳转支付界面
			payFor(){
				if(this.myIsCheck!=null){
					this.$store.commit('getVipData',this.vip1)//保存vip跳转数据
					this.$router.push({path:'/PayFor'})
				}
			},
			//点击vIP卡
			myisDetail(e,a){
				this.myIsCheck=a
				this.vip1=e
			},
			//获取VIP数据
			vipData(){
				this.$axios({
					url:'member/PersonalVip',
					method:'post',
					data:{
						member_id:this.$store.state.userData.user_id
					}
				}).then(res=>{
					this.vipList=res.data.data
				})
			},
			handleAvatarSuccess(res,file){
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			//上传图片
			// updateImg(e){
			// 	this.$axios({
			// 		url:'upload',
			// 		method:'post'
			// 	}).then(res=>{
			// 		console.log(res,e)
			// 	})
			// },
			//跳转测评结果页面
			testDetail(e){
			this.$router.push({path:'/ceshi/resulte',query:{id:e.id,nid:1}})
			},
			//跳转学校详情页面
			isDetail(e){
				this.$store.commit('detail',{school_id:e.school.school_id,spare_type:1})
				this.$router.push({path:'/Detail'})
			},
			//高考倒计时
			timeData(){
				this.$axios({
					url:'tips',
					method:'post'
				}).then(res=>{
					this.timeDay=res.data.data.day
				})
			},
			//左边选择框
			isOk(e){
				this.isCheck=e
				if (e == 1) this.getSpare()
				if (e == 2) this.getVolunteer()
				if (e == 3) this.getEvaluation_list()
				if (e == 4) this.vipData()
			},
			genderChange (e) {
				this.form.gender = e
			},
			//我关注的院校
			currentChange3(e){
				this.$axios({
					url:'/spare',
					method: 'post',
					data:{
						page:e,
						limit:4
					}
				}).then(res=>{
					this.spare= res.data.data
				})
			},
			getSpare(){
				this.$axios({
					url:'/spare',
					method: 'POST',
					data:{
						page:1,
						limit:4
					}
				}).then(res=>{
					this.spare = res.data.data
				})
			},
			getVolunteer(){
				this.$axios({
					url:'/volunteer',
					method: 'POST'
				}).then(res=>{
					this.volunteer = res.data.data
				})

			},
			getEvaluation_list () {
				this.$axios({
					url:'/review_api/evaluation_list',
					method: 'POST',
					data: {page: 1,user_id: this.form.user_id,limit: '5'}
				}).then(res=>{
					this.evaluation = res.data
				})
			},
      //改变当前页
			// currentChange(val){
			// 	this.currentPage1=val;
			// },
			//改变当前页的方法专业评测分析报告
				currentChange1(val){
					this.$axios({
						url:'/review_api/evaluation_list',
						method: 'POST',
						data: {page:val,user_id: this.form.user_id,limit: '5'}
					}).then(res=>{
						this.evaluation = res.data
					})
				},
			onSubmit(){
				if(!this.form.member_name){
					return this.$message({message:'请输入您的姓名',type:'warning'})
				}else if(!this.form.gender){
					return this.$message({message:'请选择性别',type:'warning'})
				}else if(!this.form.nemt_year){
					return this.$message({message:'请选择报考年份',type:'warning'})
				}else if(!this.form.province){
					return this.$message({message:'请选择报考省份',type:'warning'})
				}else if(!this.form.exam_type){
					return this.$message({message:'请选择考试类型',type:'warning'})
				}else if(this.form.member_name&&this.form.gender&&this.form.nemt_year&&this.form.province&&this.form.exam_type){
					this.$axios({
						url:'Login/perfect_info',
						method: 'post',
						data:this.form
					}).then(res=>{
					if(res.data.status==200){
						this.$message(res.data.msg);
						this.$store.commit('userInfo',res.data.data)
						this.$router.push({path:'/index'})
						}
					})
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.content{
   .bg_img{
       margin: 20px 0px 20px 0px;
       width: 1400px;
       height: 135px;
     }
     .c_content{
       width: 1200px;
       margin-top: 20px;
        .c_img{
       width: 1200px;
       height: 471px;
     }
     .c_all{
       display: flex;
       justify-content: center;
       position: relative;
       margin: 100px 0px 40px 0px;
       height:70px;
       .c_title1{
         font-size:34px;
         font-weight: 600;
         color: #4E6EF2;
       }
       .c_title2{
         width: 71px;
         height:8px;
         background: #4E6EF2;
         border-radius: 5px;
         position: absolute;
         bottom: 0px;
       }
     }
     .c_title5{
         display: flex;
         align-items: center;
         padding-left: 42px;
         box-sizing: border-box;
         font-size:20px;
         font-weight: 400;
         color: #333333;
         margin-bottom: 30px;
     }
     .c_title3{
       font-size: 20px;
       font-weight: 400;
       color: #333333;
       text-align: left;
       margin-bottom: 30px;
     }
     .c_title4{
       font-size: 20px;
       font-weight: 400;
       color: #333333;
       margin-bottom: 40px;
       text-align: left;
       margin-left:20px;
     }
     .f_content{
       .d_title1{
         height: 80px;
         width: 1400px;
         background: #F2F8FF;
         display: flex;
         align-items: center;
         padding-left: 42px;
         box-sizing: border-box;
         font-size:20px;
         font-weight: 400;
         color: #337CFF;
       }
       .d_title4{
         height: 80px;
         width: 1400px;
         background: #F2F8FF;
         display: flex;
         align-items: center;
         padding-left: 42px;
         box-sizing: border-box;
         font-size:20px;
         font-weight: 400;
         color: #333333;
       }
       .d_all{
         width: 1400px;
         padding: 30px 42px;
         box-sizing: border-box;
         background: #FFFFFF;
         .d_title2{
          font-size:24px;
          font-weight: 400;
          color: #333333;
          text-align: left;
         }
         .d_title3{
           margin-top: 30px;
          font-size:16px;
          font-weight: 400;
          color: #707070;
          text-align: left;
         }
         .d_title5{
          font-size:16px;
          font-weight: 400;
          color: #707070;
          text-align: left;
         }
       }
     }
     }
     .d_content{
       margin-top: 60px;
       display: flex;
       flex-wrap: wrap;
       .d_all{
         margin-bottom: 43px;
         width:280px;
         .d_title1{
           width: 140px;
           height: 140px;
           background: #4E6EF2;
           border-radius: 50%;
           display: flex;
           align-items: center;
           justify-content: center;
           margin: auto;
           .iconfont{
             color: #FFFFFF;
             font-size: 67px;
           }
         }
         .d_title2{
           font-size: 26px;
           font-weight: 400;
           color: #000000;
           margin-top: 20px;
         }
       }
     }
}
	.content {
		display: flex;
		justify-content: center;
		.a_content{
			display: flex;
			justify-content: space-between;
			width: 1200px;
			.a_left{
				width: 230px;
				height:761px;
				background-color: #FFFFFF;
				.f_all{
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom:20px;
					.f_title1{
						width: 80px;
						height: 20px;
						background: #337CFF;
						border-radius: 999px;
						font-size: 12px;
						font-weight: 400;
						color: #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 35px;
					}
					.f_title2{
						font-size: 14px;
						font-weight: 400;
						color: #337CFF;
						cursor: pointer;
					}
				}
				.a_img{
					width: 80px;
					height: 80px;
					background: #CCCCCC;
					border-radius: 50%;
					margin: 20px auto;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.a_title1{
					font-size: 14px;
					font-weight: 400;
					color: #999999;
					margin-bottom: 20px;
					text-align: center;
				}
				.a_title2{
					width: 228px;
					height: 55px;
					background: #F2F8FF;
					border-right:2px solid #337CFF;
					font-size: 16px;
					font-weight: 400;
					color: #337CFF;
					display:flex;
					align-items: center;
					justify-content: center;
				}
				.a_title3{
					width: 230px;
					height: 55px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					display:flex;
					align-items: center;
					justify-content: center;
				}
			}
			.a_right{
				width:950px;
				height:761px;
				background-color: #FFFFFF;
				padding: 20px 0px 40px 60px;
				box-sizing: border-box;
				.g_content{
					.g_title1{
						font-size: 20px;
						font-weight: 400;
						color: #666666;
					}
					.g_all{
						width:870px;
						padding: 20px;
						box-sizing: border-box;
						background: #F2F8FF;
						margin-top: 30px;
						.g_title2{
							display: flex;
							align-items: center;
							margin-bottom: 36px;
							flex-wrap: wrap;
							// justify-content: space-around;
						.g_title8{
							display: flex;
							justify-content: center;
							flex-direction: column;
							align-items: center;
							margin-bottom:20px;
							.yuan{
								margin-top: 10px;
								width: 15px;
								height: 15px;
								border-radius: 50%;
								background-color: #4B7BF7;
								border: 1px solid #4B7BF7;
							}
						.g_title9{
							display: flex;
							align-items: center;
							margin: 0px 10px;
							padding: 20px;
							box-sizing: border-box;
							width: 191px;
							height: 115px;
							background: #65ACFF;
							border-radius: 10px;
							justify-content:space-between;
							.g1_img{
								height: 100px;
								width: 30px;
							}
							.g_title10{
							font-size:12px;
							font-weight: 400;
							color: #333333;

							}
							.g_title11{
							font-size:12px;
							font-weight: 500;
							color: #333333;
							margin: 15px 0px;
							}
							.g_title12{
							font-size:12px;
							font-weight: 400;
							color: #666666;
							}
						}	
						}
						}
						.g_title3{
							font-size: 18px;
							font-weight: 400;
							color: #666666;
						}
						.g_title4{
							font-size: 16px;
							font-weight: 400;
							margin: 10px 0px;
							color: #999999;
						}
						.g_title5{
							display: flex;
							align-items: center;
							.g_title6{
							font-size: 16px;
							font-weight: 400;
							color:#999999;
							}
							.g_title7{
							font-size: 16px;
							font-weight: 400;
							color: #F94D28;
							}
						}
					}
					.g_title13{
						width: 140px;
						height: 50px;
						background: #337CFF;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 16px;
						font-weight: 400;
						color: #FFFFFF;
						margin: 50px auto;
					}
				}
				.avatar-uploader {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
					width: 178px;
					height: 178px;
				}
				.avatar-uploader:hover {
					border-color: #409EFF;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 100%;
					height: 100%;
					line-height: 178px;
					text-align: center;
				}
        .b_content{
          .b_right{
            display: flex;
            .b_title1{
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              box-sizing: border-box;
              margin: 0px 30px 0px 0px;
              border-bottom: 2px solid #337CFF;
              padding-bottom: 6px;
              color:#337CFF;
            }
          }
          .b_next{
            margin-top: 22px;
            text-align: left;
            display: flex;
            align-items: center;
            border-bottom: 2px solid #F0F5FF;
            padding-bottom: 17px;
            .b_img{
              width: 100px;
              height: 100px;
              background: #A51616;
              margin-right: 22px;
              border-radius: 50%;
            }
            .b_title1{
              .b_title2{
                font-size: 20px;
                font-weight: 400;
                color: #333333;
              }
              .b_title3{
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                margin: 13px 0px;
              }
              .b_title4{
                display: flex;
                .b_title5{
                  padding: 5px 10px;
                  background: #F0F5FF;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  font-weight: 400;
                  color: #337CFF;
                  margin-right: 16px;
                }
                .b_title6{
                  padding: 5px 10px;
                  background:#DDF7E2;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  font-weight: 400;
                  color: #3DB159;
                  margin-right: 16px;
                }
                .b_title7{
                  padding: 5px 10px;
                  background:#FFF1F0;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  font-weight: 400;
                  color: #FF4D4F;
                }
              }
            }
          }
        }
        .c_next{
            .c_title1{
              font-size: 20px;
              font-weight: 400;
              color: #337CFF;
              text-align: left;
            }
            .c_title2{
              padding-bottom: 21px;
              border-bottom: 2px solid #F5F5F5;
              .c_title3{
                font-size: 20px;
                font-weight: 400;
                color: #333333;
                text-align: left;
                 margin: 21px 0px;
              }
              .c_title4{
                display: flex;
                align-items: center;
                text-align: left;
                .c_title5{
                  font-size: 16px;
                  font-weight: 400;
                  color: #999999;
                  margin-right: 100px;
                }
              }
            }
          }
			}
		}
	}
</style>
