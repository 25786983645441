<template>
<div>
	<myNav/>
	<!-- <loginPop/> -->
	<div class="content">
	<div class="a_content">
	<div class="a_all">
		<div class="a_title1">欢迎您使用</div>
		<div class="a_title2">高考填报模拟系统</div>
		<div class="a_title3">领先的大数据分析技术，整合历年考生录取情况，通过院校优先、专业优先、一键填报三大方向助考生快速锁定意向大学。</div>
		<div class="a_title3">流程：考生输入分数、科目—选择批次—只能推荐冲、稳、 保三种风险的院校和专业—选择意向志愿，生成模拟志愿表</div>
	</div>
	<div class="a_title4">
	<div class="a_title5">
		<div class="a_title6">
		<div class="a_title7">
			<div>
				<div class="a_title8">新高考模式</div>
				<div class="a_title9">
				<!-- <div class="a_title10">
					<div class="a_title16">预估总分</div>
					<div>
						<el-form>
							<el-form-item label="地区：">
							<el-select v-model="form.addit" placeholder="请选择性别" style="width: 100%" @change="genderChange">
								<el-option label="男" :value="2"></el-option>
								<el-option label="女" :value="1"></el-option>
							</el-select>
							</el-form-item>
						</el-form>
					</div>
				</div> -->
				<div class="a_title12">
					<div class="a_title13">选考科目</div>
					<div class="a_title14" v-show="type == 0">
						<div class="b_all" @click="list.subject = 2">
							<div :class="list.subject == 2 ? 'b_title2' : 'b_title1'"></div>
							<div class="b_title3">文科</div>
						</div>
						<div class="b_all" @click="list.subject = 1">
							<div :class="list.subject == 1 ? 'b_title2' : 'b_title1'"></div>
							<div class="b_title3">理科</div>
						</div>
					</div>
					<div class="a_title14" v-show="type = 1">
						<div class="b_all" @click="isOk(item,index)"  v-for="(item,index) in art.select_type ? art.select_type[1] : 0" :key="index">
							<div style="display: flex" v-show="item.group == 2">
								<div :class="select_type.indexOf(item.title) == -1 ? 'b_title1' : 'b_title2'"></div>
								<div class="b_title3">{{item.title}}</div>
							</div>
						</div>
					</div>
					<div class="a_title14" v-show="type === 2">
						<div class="b_all" @click="isOk(item,index)" v-for="(item,index) in art.select_type ? art.select_type[1] : 0" :key="index">
							<div style="display: flex" v-show="item.group == 2">
								<div :class="select_type.indexOf(item.title) == -1 ? 'b_title1' : 'b_title2'"></div>
								<div class="b_title3">{{item.title}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="a_title12" v-show="type === 2">
					<div class="a_title13">选考科目</div>
					<div class="a_title14">
						<div class="b_all" @click="isOk(item,index)" v-for="(item,index) in art.select_type ? art.select_type[1] : 0" :key="index">
							<div style="display: flex;align-items: center" v-show="item.group == 3">
								<div :class="select_type.indexOf(item.title) == -1 ? 'b_title1' : 'b_title2'"></div>
								<div class="b_title3">{{item.title}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="a_title12">
					<div class="a_title13">高考成绩</div>
					<input class="enter_box" type="number" v-model="list.fraction"/>
				</div>
				<div class="a_title12">
					<div class="a_title13">全省排名</div>
					<input class="enter_box" type="number" v-model="list.ranking"/>
				</div>
				</div>
			</div>
				<div class="a_title15" @click="submit()">确定</div>
			</div>
		</div>
	</div>
	</div>
	</div>
	</div>
	<Footer/>
</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				form:{addit:''},
				select_type: [],
				current: 0,
				isCheck:null,
				type: 1,
				art: {},
				selected_subject: [],
				province: {list: [{title:'山东',isnew:1,type:1}],index:0},
				list: {
					fraction: '',
					subject: 1,
					select_subject: {master: [],must:[],secondary:[]},
					exam_type: 1,
					province: 0
				}
			}
		},
		created() {
			this.list.exam_type = this.$store.state.memberData.exam_type
			this.list.province = this.$store.state.memberData.province_id
			this.$axios({
				url: '/pro/school',
				method: 'POST'
			}).then(res => {
				for(let i=0;i<res.data.data.length;i++){
					if (res.data.data[i].id== this.$store.state.memberData.province_id) {
						if (res.data.data[i].type) {
							this.type = 1
							this.selectList(res.data.data[i].type)
						} else {
							this.$message('您选择的高考城市未实行新高考')
							this.$router.go(-1)
						}
					}
				}
			})
			// this.$axios({
			// 	url: '/pro/school'
			// }).then(res => {
			// })

		},
		methods: {
			genderChange(){
				
			},
			//选中
			isOk(item,index){
				let of = this.select_type.indexOf(item.title)
				if (of == -1) {
					if (this.type == 1) {
						if (this.list.select_subject.secondary.length == 3) return this.$message('选科已达到上线')
						this.list.select_subject.secondary.push({title: item.title,key: index,score: '',ranking: ''})
					} else if (this.type == 2) {
						if (item.group == 2) {
							if (this.list.select_subject.must.length == 1) return this.$message('当前选科已达到上线')
							this.list.select_subject.must.push({title: item.title,key: index,score: '',ranking: ''})
						} else if (item.group == 3) {
							if (this.list.select_subject.secondary.length == 2) return this.$message('当前选科已达到上线')
							this.list.select_subject.secondary.push({title: item.title,key: index,score: '',ranking: ''})
						}
					}
					this.select_type.push(item.title)
				} else {
					if (this.type == 1) {
						this.list.select_subject.secondary.forEach((e,i) => {
							if (e.title == item.title) this.list.select_subject.secondary.splice(i,1)
						})
					} else if (this.type == 2) {
						if (item.group == 2) {
							this.list.select_subject.must.forEach((e,i) => {
								if (e.title == item.title) this.list.select_subject.must.splice(i,1)
							})
						} else if (item.group == 3) {
							this.list.select_subject.secondary.forEach((e,i) => {
								if (e.title == item.title) this.list.select_subject.secondary.splice(i,1)
							})
						}
					}
					this.select_type.splice(of,1)
				}
			},
			selectList (type) {
				this.$axios({
					url: '/login/getArt'
				}).then(res => {
					console.log(res.data.data.select_type[type])
					this.art = res.data.data
				})
			},
			//新高考成绩提交
			submit () {
				this.list.province=37
				this.list.subject=3
				this.$axios({
					url: 'member/updateResult',
					data: this.list
				}).then((res) => {
					if(res.data.status != 200) return this.$message({message:res.data.msg,type:'warning'})	
					if (res.data.status == 200) {
						this.$message({message:res.data.msg,type:'success'})
						this.list = res.data
						this.selected_subject = []
						for (var i = 0; i < this.province.list.length; i++) {
							if (this.province.list[i].id == this.list.province) {
								this.province.index = i
								if (!this.list.select_subject) {
									this.list.select_subject = {master: [],must:[],secondary:[]}
									if (this.province.list[i].type) {
										this.art.select_type[this.province.list[i].type].forEach((item,index) => {
											if (item.group == 1) this.list.select_subject.master.push({title: item.title,key: index,score: '',ranking: ''})
										})
									}
								} else if (this.province.list[i].type) {
									if (this.province.list[i].type == 1) {
										this.list.select_subject.secondary.forEach(item => {
											this.selected_subject.push(item.title)
										})
									} else if (this.province.list[i].type == 2) {
										this.selected_subject.push(this.list.select_subject.must[0].title)
										this.list.select_subject.secondary.forEach(item => {
											this.selected_subject.push(item.title)
										})
									}
								}
								break
							}
						}
					}
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
        .content{
           .a_content{
               display: flex;
               justify-content: space-between;
               width: 1200px;
               height: 839px;
               background: linear-gradient(270deg, #217CD8 0%, #3030B4 100%);
               display: flex;
               align-items: center;
               padding: 0px 100px;
               box-sizing: border-box;
				margin-bottom: 100px;
				margin-top: 1px;
               .a_all{
                   width: 383px;
                 .a_title1{
                    font-size: 18px;
                    font-weight: 400;
                    color: #FEA52D;
                 }
                 .a_title2{
                    font-size:24px;
                    font-weight: 400;
                    color: #FEA52D;
                    margin: 10px 0px 0px 0px;
                 }
                 .a_title3{
                    font-size: 22px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 30px;
                    text-align: left;
                 }
               }
               .a_title4{
                   width:532px;
                   height: 422px;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   .a_title5{
                        width: 592px;
                        height: 380px;
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 10px;
                        display: flex;
                   align-items: center;
                   justify-content: center;
                        .a_title6{
                        width: 592px;
                        height: 332px;
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .a_title7{
							align-items: center;
                        width: 482px;
                        height: 422px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        padding: 30px 0px;
						box-sizing: border-box;
						justify-content: center;
                        .a_title8{
                            width: 182px;
                            padding-bottom: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 24px;
                            font-weight: 400;
                            color: #3B68DF;
                            margin: auto;
							border-bottom: 2px solid #3B68DF;
                        }
                        .a_title9{
                            .a_title10{
                                display: flex;
                                align-items: center;
                                margin-top: 40px;
                                justify-content: center;
                                .a_title16{
                                    margin-right: 20px;
                                    font-size: 22px;
                                    font-weight: 400;
                                    color: #333333;
                                }
                                .a_title11{
                                    width: 210px;
                                    height: 42px;
                                    background: #F7F7F7;
                                    border-radius: 5px;
									border:none;
									outline:none;
                                }
                            }
                            .a_title12{
                                margin:15px auto;
                                display: flex;
								justify-content: center;
								.enter_box{
									border:none;
									outline:none;
									border: 1px solid #999999;
								}
                                .a_title13{
								margin-right: 24px;
                                font-size: 22px;
                                font-weight: 400;
                                color: #333333;
                                }
								.a_title14{
								width:300px;
								display: flex;
								flex-wrap: wrap;
								.b_all{
									display: flex;
									align-items: center;
									// margin: 0px 20px 20px 0px;
									width:100px;
									.b_title1{
										width: 20px;
										height: 20px;
										background: #FFFFFF;
										border: 1px solid #707070;
										border-radius: 5px;
										display: flex;
										align-items: center;
										justify-content:center;
									}
									.b_title2{
										width: 20px;
										height: 20px;
										background: #3B68DF;
										border: 1px solid #3B68DF;
										border-radius: 5px;
										display: flex;
										align-items: center;
										justify-content:center;
									}
									.b_title3{
										margin-left: 5px;
										font-size: 19px;
										font-weight: 400;
										color: #333333;
										margin-right: 1px;
									}
								}
								}
                            }
                        }
						.a_title15{
							width: 197px;
							height: 43px;
							background: #3B68DF;
							border-radius: 9px;
							margin: auto;
							font-size: 15px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
						}
                        }
                        }
                   }
               }
           }
        }
</style>
