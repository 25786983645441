<template>
	<div>
		<myNav />
		<!-- <loginPop/> -->
		<div class="tt_content">
			<div class="a_title1">
				<div v-for="(item,index) in listData" :key="index" :class="index1==index ? 'a_title2' : 'aa_title2'"
					@click="fen(index)">{{item.name}}</div>
			</div>
			<div v-if="index1==0">
				<div class="a_all">
					<div><input class="a_input" type="text" placeholder="请输入院校名称"></div>
					<div class="a_title3">
						<i class="iconfont icon-search"></i>
						<span class="a_title4">搜索</span>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">招生地区</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">山东</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">科目</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 2" :key="index"
						@click="isOk(index)">理科</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">北京</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">理工类</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">年份</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">2020</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">分数</div>
					<div><input class="p_input" type="text" placeholder="请输入分数"></div>
					<div>
						<el-form style="display: flex;align-items: center;margin-top:23px;" :inline="true"
							:model="formInline" class="demo-form-inline">
							<el-form-item>
								<el-select v-model="formInline.name" placeholder="无浮动区间">
									<el-option label="区域一" value="shanghai"></el-option>
									<el-option label="区域二" value="beijing"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div class="sou">搜索</div>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div class="a_title9" v-for="(item,index) in 6" :key="index">
					<div class="a_title10" style="background-color: #FFFFFF;" v-for="(item,index) in tableData"
						:key="index">{{item.name}}</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="100" :page-size="4"
						:pager-count="7" :current-page="1" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
			<div v-if="index1==1">
				<div class="a_all">
					<div><input class="a_input" type="text" placeholder="请输入院校名称"></div>
					<div class="a_title3">
						<i class="iconfont icon-search"></i>
						<span class="a_title4">搜索</span>
					</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">招生地区</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">山东</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">年份</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">2020</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">批次段</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 2" :key="index"
						@click="isOk(index)">一线段</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校省份</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">北京</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">院校类型</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">理工类</div>
				</div>
				<div class="a_title5">
					<div class="a_title6">选科要求</div>
					<div :class="isCheck==index ? 'a_title7' : 'a_title8'" v-for="(item,index) in 5" :key="index"
						@click="isOk(index)">化学</div>
					<div class="a_title7" style="border-radius:10px;" @click="search()">查询</div>
					<div class="a_title7" style="background-color:#FFFFFF;color: #F5F5F5;">最多可选择3个科目</div>
				</div>
				<div class="a_title9">
					<div class="a_title10" v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
				</div>
				<div class="a_title9" v-for="(item,index) in 6" :key="index">
					<div class="a_title10" style="background-color: #FFFFFF;color: #666666;"
						v-for="(item,index) in tableData" :key="index">{{item.name}}</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
					<el-pagination background hide-on-single-page layout="prev,pager,next" :total="100" :page-size="4"
						:pager-count="7" :current-page="1" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				formInline: {
					name: ''
				},
				index1: 0,
				isCheck: 0,
				listData: [{
					name: '院校投档线(文理)'
				}, {
					name: '院校投档线(新高考)'
				}],
				titleData: [{
					name: '院校名称'
				}, {
					name: '年份'
				}, {
					name: '批次'
				}, {
					name: '投档线'
				}, {
					name: '投档线位次'
				}],
				tableData: [{
					name: '北京大学'
				}, {
					name: '2019'
				}, {
					name: '本科批'
				}, {
					name: '705'
				}, {
					name: '687'
				}]
			};
		},
		activated() {
			//无需登录
			//if(!this.$store.state.userData.token) return 
		},
		methods: {
			fen(e) {
				this.index1 = e
			},
			//选中状态
			isOk(e) {
				this.isCheck = e
			},
			//分页
			currentChange(e) {
				console.log(e)
			},
			//查询
			search() {

			}
		},
	}
</script>

<style lang="less" scoped>
	.tt_content {
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;

		.a_title1 {
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;

			.a_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
				border-bottom: 2px solid #3B68DF;
				color: #3B68DF;
			}

			.aa_title2 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
			}
		}

		.a_all {
			padding-left: 20px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			margin: 30px 0px;

			.a_input {
				width: 817px;
				height: 42px;
				background: #F2F8FF;
				border: none;
				outline: none;
			}

			.a_title3 {
				display: flex;
				align-items: center;
				width: 130px;
				height: 40px;
				background: #3B68DF;
				justify-content: center;

				.iconfont {
					color: #FFFF00;
				}

				.a_title4 {
					font-size: 18px;
					font-weight: 400;
					margin-left: 5px;
					color: #FFFFFF;
				}
			}
		}

		.a_title5 {
			display: flex;
			align-items: center;
			margin-bottom: 23px;

			.a_title6 {
				margin-right: 40px;
				font-size: 20px;
				font-weight: 400;
				color: #333333;
				width: 100px;
				text-align: right;
			}

			.p_input {
				width: 156px;
				height: 40px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				border: none;
				outline: none;
				margin-right: 20px;
			}

			.sou {
				width: 86px;
				height: 35px;
				background: #3B68DF;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.a_title7:hover {
				cursor: pointer;
			}

			.a_title8:hover {
				cursor: pointer;
			}

			.a_title7 {
				padding: 5px 23px;
				background: #3B68DF;
				font-size: 20px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
			}

			.a_title8 {
				padding: 5px 23px;
				background: #F7F7F7;
				font-size: 20px;
				font-weight: 400;
				color: #333333;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
			}
		}

		.a_title9 {
			display: flex;
			justify-content: center;

			.a_title10 {
				width: 120px;
				height: 55px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 120px;
			}
		}
	}
</style>
