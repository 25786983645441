<template>
	<div>
		<myNav/>
		<div style="background-color: #FFFFFF;width: 100%;height: 100%;">
			<div class="m_content">
				<div class="a_title1">
					<div class="a_title2">志愿填报</div>
					<!-- <div class="a_title3">省份：山东</div>
					<div class="a_title3">分数：455</div>
					<div class="a_title3">科目：物理+历史+生物</div>
					<div class="a_title4">更改</div> -->
				</div>
				<!-- <div class="h_content">
					<div class="h_title1">院校属地</div>
					<div class="h_all">
						<div class="h_title2" v-for="(item,index) in 22" :key="index">安徽</div>
					</div>
				</div> -->
				<!-- <div class="b_content">
					<div class="b_title1">院校类型</div>
					<div class="b_all">
						<div class="b_title2" v-for="(item,index) in 9" :key="index">综合类</div>
					</div>
				</div> -->
				<!-- <div class="b_content">
					<div class="b_title1">专业查询</div>
					<div><input class="b_input" type="text" placeholder="请输入专业名称"></div>
					<div class="b_title3">查询</div>
				</div> -->
				<div class="b_content" style="margin-top: 10px;">
					<div class="b_title1">院校查询</div>
					<div><input v-model="title" class="b_input" type="text" placeholder="请输入院校名称"></div>
					<div class="b_title3" @click="searchBtn()">查询</div>
				</div>
				<div class="d_content">
					<div @click="isOk(index)" :class="isCheck==index ? 'd_title1' : 'd_title2'" v-for="(item,index) in y_data" :key="index">{{item.name}}</div>
				</div>
				<div v-loading="loading" :style="loading ? {'margin-top':'100px'} : ''">
					<div v-for="(item,index) in list.data" :key="index">
						<div class="f_content">
							<div class="f_all" @click="detail(item.school_id)">
								<div><img class="f_img" :src="item.logo ? item.logo : ''" alt=""></div>
								<div class="f_title1" style="width: 300px;">
									<div class="f_title2">{{item.title}}</div>
									<div class="f_title3">
										<div class="f_title4">{{item.province_name}}</div>
										<!-- <div class="f_title5">招生代码D326</div> -->
									</div>
									<div style="font-size: 16px;font-weight: 400;color: #999999;margin-top: 10px;">{{item.major}}</div>
									<div class="f_title5">
										<!-- <div class="f_title6">公办</div> -->
										<div class="f_title7" v-if="item.f985==1">985</div>
										<div class="f_title8" v-if="item.f211==1">211</div>
										<div class="f_title9" v-if="item.dual_class_name">{{item.dual_class_name}}</div>
									</div>
								</div>
								<div class="i_all" style="margin-left:80px;width: 180px;">
									<div class="i_title1">{{item.reference_year}}年最低分</div>
									<div class="i_title2">{{item.lowest_score}}</div>
								</div>
								<div class="shu"></div>
								<div class="i_all" style="margin:0px 63px;">
									<div class="i_title1">{{item.reference_year}}最低位次</div>
									<div class="i_title2">{{item.lowest_order}}</div>
								</div>
								<div class="shu"></div>
								<div class="i_all" style="margin-left:63px;">
									<div class="i_title1">{{item.reference_year}}年学校排名</div>
									<div class="i_title2">{{item.zr_rank}}</div>
								</div>
							</div>
						</div>
						<!-- <div class="g_content">
							<div class="g_title1">概率</div>
							<div class="g_title1" style="width: 196px;">招生专业（类）</div>
							<div class="g_title1" style="width:123px;">选择要求</div>
							<div class="g_title1" style="width:282px;">2020招生计划</div>
							<div class="g_title1" style="width:123px;">2019</div>
							<div class="g_title1" style="width:123px;">2018</div>
							<div class="g_title1" style="width:123px;">2017</div>
							<div class="g_title1" style="width:123px;"></div>
						</div>
						<div class="i_content">
							<div class="i_title1">
								<div class="i_title2">39%</div>
								<div class="i_title3">风险高</div>
							</div>
							<div class="i_title1" style="width:195px;display: flex;justify-content: center;align-items: center;">
								<div class="i_title3">会计学</div>
							</div>
							<div class="i_title1" style="width:123px;display: flex;justify-content: center;align-items: center;">
								<div class="i_title3">不限</div>
							</div>
							<div class="i_all">
								<div class="i_title4">
									<div class="i_title5">专业代码</div>
									<div class="i_title5" style="width: 56px;">学制</div>
									<div class="i_title5">学费</div>
									<div class="i_title5">人数</div>
								</div>
								<div class="i_title4">
									<div class="i_title5" style="height: 77px;">B8</div>
									<div class="i_title5" style="width: 56px;height: 77px;">4</div>
									<div class="i_title5" style="height: 77px;">18000</div>
									<div class="i_title5" style="height: 77px;">5</div>
								</div>
							</div>
							<div class="i_title6">
								<div class="i_title7">理</div>
								<div class="i_title7">461</div>
								<div class="i_title7">163828</div>
							</div>
							<div class="i_title6">
								<div class="i_title7">理</div>
								<div class="i_title7">461</div>
								<div class="i_title7">163828</div>
							</div>
							<div class="i_title6">
								<div class="i_title7">理</div>
								<div class="i_title7">461</div>
								<div class="i_title7">163828</div>
							</div>
							<div class="i_title6">
								<div class="i_title7" style="color: #d34b16;">选择专业</div>
							</div>
						</div> -->
					</div>
				</div>
				<div class="search_all">
					<el-pagination
					background
					hide-on-single-page
					layout="prev,pager,next"
					:total="list.counts"
					:page-size="list.limit"
					:pager-count="7"
					:current-page="list.page"
					@current-change="currentChange"
					>
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				y_data:[{name:'冲一冲'},{name:'稳一稳'},{name:'保一保'}],
				isCheck:0,
				list:[],
				fill:{page:1},
				title:'',
				loading:true
			}
		},
		activated(){
			this.isCheck=0
			this.dataAll()
		},
		methods: {
			//跳转学校详情页面
			detail(e){
				this.$router.push({path:'/Detail', query: {school_id:e}})
			},
			isOk(e){
				this.fill.page=1
				this.list=[]
				this.loading=true
				this.isCheck=e
				this.fill.type_sign=e+1
				this.$axios({
					url:'IntelligentFilling/serachScore',
					method:'post',
					data:this.fill
				}).then(res=>{
					this.list=res.data
					this.loading=false
				})
			},
			//获取所有数据
			dataAll(){
				this.fill=this.$store.state.form
				this.fill.type_sign=1
				this.fill.page=1
				this.fill.user_id=''
				// this.fill.user_id=this.$store.state.userData.user_id
				this.$axios({
					url:'IntelligentFilling/serachScore',
					method:'post',
					data:this.fill
				}).then(res=>{
					this.list=res.data
					this.loading=false
				})
			},
			//分页
			currentChange(e){
				this.list=[]
				this.loading=true
				this.fill.page=e
				this.$axios({
					url:'IntelligentFilling/serachScore',
					method:'post',
					data:this.fill
				}).then(res=>{
					this.list=[]
					this.list=res.data
					this.loading=false
				})
			},
			//院校查询
			searchBtn(){
				this.list=[]
				this.loading=true
				this.fill.title=this.title
				this.$axios({
					url:'IntelligentFilling/serachScore',
					method:'post',
					data:this.fill
				}).then(res=>{
					this.list=res.data
					this.loading=false
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.m_content {
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;
		.a_title1{
			display: flex;
			align-items: center;
			padding: 26px 0px;
			box-sizing: border-box;
			.a_title2{
				font-size: 24px;
				font-weight: 400;
				color: #333333;
			}
			.a_title3{
				font-size: 18px;
				font-weight: 400;
				color: #333333;
				margin-left: 30px;
			}
			.a_title4{
				width: 72px;
				height: 37px;
				background: #d34b16;
				border-radius: 6px;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;
			}
		}
		.h_content{
			display: flex;
			align-items: center;
			.h_title1{
				width: 112px;
				height: 148px;
				background: #F4F5F7;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
			}
			.h_all{
				width: 1088px;
				display: flex;
				flex-wrap: wrap;
				.h_title2{
					padding: 5px 18px;
					box-sizing: border-box;
					font-size: 16px;
					font-weight: 400;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0px 20px 20px 0px;
					background-color: #d34b16;
					border-radius: 19px;
				}
			}
		}
		.b_content{
			display: flex;
			align-items: center;
			margin: 10px 0px;
			.b_title1{
				width: 112px;
				height:74px;
				background: #F4F5F7;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
			}
			.b_input{
				width: 256px;
				height: 37px;
				border: 1px solid #D1D1D1;
				border-radius: 3px;
				//border: none;
				outline:none;
				margin-right: 31px;
				padding-left: 20px;
				box-sizing: border-box;
			}
			.b_title3{
				width: 72px;
				height: 37px;
				background: #d34b16;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				border-radius: 19px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.b_all{
				width: 1088px;
				display: flex;
				flex-wrap: wrap;
				.b_title2{
					padding: 5px 18px;
					box-sizing: border-box;
					font-size: 16px;
					font-weight: 400;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0px 20px 20px 0px;
					background-color: #d34b16;
					border-radius: 19px;
				}
			}
		}
		.d_content{
			width:1200px;
			height: 60px;
			background-color: #F4F5F7;
			display: flex;
			align-items: center;
			justify-content: center;
			.d_title1{
			background: #FD6164;
			display: flex;
			align-items: center;
			justify-content: center;	
			width: 400px;
			height: 60px;
			font-size: 20px;
			font-weight: 400;
			color: #FFFFFF;
			}
			.d_title2{
			background: #F4F5F7;
			display: flex;
			align-items: center;
			justify-content: center;	
			width:400px;
			height: 60px;
			font-size: 20px;
			font-weight: 400;
			color: #333333;
			}
		}
		.search_all{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 200px;
		}
		.f_content{
			padding: 28px 40px;
			box-sizing: border-box;
			border: 1px solid #C9C9C9;
			margin-top: 40px;
			.f_all{
				display: flex;
				align-items: center;
				
				.f_img{
					width: 94px;
					height: 94px;
					border-radius: 50%;
					margin-right: 20px;
				}
				.f_title1{
					.f_title2{
						font-size: 24px;
						font-weight: 400;
						color: #333333;
					}
					.f_title3{
						margin-top: 9px;
						display:flex;
						align-items: center;
						.f_title4{
							height: 22px;
							font-size: 16px;
							font-weight: 400;
							color: #999999;
						}
					}
					.f_title5{
					display: flex;
						align-items: center;
						margin-top: 10px;
						.f_title6{
							width: 35px;
							height: 21px;
							background: #F0F5FF;
							border-radius: 4px;
							font-size: 12px;
							font-weight: 400;
							color: #d34b16;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.f_title7{
							width: 32px;
							height: 21px;
							background: #FFF1F0;
							opacity: 1;
							border-radius: 4px;	
							font-size: 12px;
							font-weight: 400;
							color: #FF4D4F;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 0px 16px 0px 0px;
						}
						.f_title8{
							width: 28px;
							height: 21px;
							background: #DDF7E2;
							opacity: 1;
							border-radius: 4px;
							font-size: 12px;
							font-weight: 400;
							color: #3DB159;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.f_title9{
							width: 47px;
							height: 21px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 4px;
							font-size: 12px;
							font-weight: 400;
							color: #d34b16;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 16px;
						}
					}
				}
				.i_all{
					.i_title1{
						font-size: 16px;
						font-weight: 400;
						color: #666666;
						text-align: center;
					}
					.i_title2{
						font-size: 20px;
						font-weight: 400;
						color: #333333;
						margin-top: 23px;
						text-align: center;
					}
				}
				.shu{
					width: 0px;
					height: 45px;
					border: 1px solid #CCCCCC;
				}
			}
		}
	}
</style>
