<template>
	<div>
		<myNav/>
		<div class="content">
			<div>
				<div class="f_title1">
					<div :class="group.type==1 ? 'f_title2' : 'f_title3'" @click="majorGroup(1)">本科专业列表</div>
					<div :class="group.type==106 ? 'f_title2' : 'f_title3'" @click="majorGroup(106)">专科专业列表</div>
				</div>
				<!-- <div class="f_all">
					<div class="f_title4" @click="myDetail(item)" v-for="(item,index) in group.list" :key="index">{{item.name}}</div>
				</div> -->
				<div v-for="(item,index) in group.list" :key="index">
					<div class="f_title1" style="margin-top:0px">
						<div class="f_title5">
						{{item.name}}</div>

						<div class="f_title6">{{item.children.length}}个专业</div>
					</div>
					<div class="f_title111" style="margin-top:0px;background:#FFFFFF;">

					</div>
					<div class="f_all" style="padding-top:0px">
						<div class="f_title4" @click="myDetail(item_1)" v-for="(item_1,index_1) in item.children" :key="index_1">{{item_1.name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				index1:0,
				school:1,
				currentPage:1,
				isCheck:0,
				isCheck1:1,
				list1:[{title:'哲学22'},{title:'经济学'},{title:'法学'},{title:'教育学'},{title:'文学'},{title:'工学'},{title:'农学'},{title:'医学'},{title:'军事学'},{title:'管理学'},{title:'艺术学'},{title:'其他'}],
				list2:[{name:'学校概括'},{name:'录取信息'},{name:'招生计划'},{name:'选考要求'},{name:'简章查询'},{name:'高校就业'}],
				// 学校列表 - 详情
				province_list: [],
				searchCondition: {}, // 筛选条件
				filter: {
					school_type: '',
					is_pubilc: '',
					dual_class: '',
					f211: '',
					f985: '',
					type: ''
				},
				// 批次线
				batch: {},
				batchData: {
					year: '',
					province_id: ''
				},
				batchList: {},

				// 专业
				group: {
					list: [],
					type: 1
				},
				detail: {}
			}
		},
		activated(){
			this.majorGroup()
		},
		methods: {
			//专业列表
			myDetail(e){
				this.$router.push({path:'/mySearch/proList',query:{id:e.level_id}})
			},
			majorGroup (i = 1) {
				this.group.type = i
				this.$axios({
					url: '/major/group',
					method: 'POST',
					data: {
						type: this.group.type
					}
				}).then(res => {
					this.group.list = res.data.data
				})
			},
			isLie(e){
				this.isCheck1=e
			},
			//改变当前页的方法
			currentChange(val){
				this.currentPage=val;
			},
			//学校详情
			details(id){
				this.myValue = null

				this.$axios({
					url: 'School/detail',
					method: 'POST',
					data: {school_id: id}
				}).then(res=>{
					this.detail = res.data.data.detail
				})
			},
			xuanZe(e){
				this.index1=e
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.top_img{
			width: 1200px;
			height: 500px;
		}
		.img_title1{
			background-color: #FFFFFF;
			padding: 25px 40px 0px 40px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			.img_title2{
				width: 970px;
				height: 55px;
				background-color: #F2F8FF;
				.img_title3{
					width: 1140px;
					height: 50px;
					background-color: #F2F8FF;
				}
			}
			.img_title4{
				display: flex;
				align-items: center;
				width: 152px;
				height: 55px;
				background: #3B68DF;
				justify-content: center;
				.iconfont{
					color: #FFFFFF;
				}
				.img_title5{
					font-size:16px;
					font-weight: 400;
					color: #FFFFFF;
					margin-left: 8px;
				}
			}
		}
		.a_all {
			padding: 35px 19px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.a_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}

			.a_title2 {
				display: flex;
				align-items: center;

				.a_title3 {
					margin: 47px 0px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
				}

				.a_title4 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
			}

			.a_title5 {
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				display: flex;
				flex-wrap: wrap;
				.a_title6{
					font-size: 22px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					display: flex;
					margin-top: 7px;
				}
				.a_title7{
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
				.a_title8{
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color:#337CFF;
					margin-left: 47px;
				}
			}
		}
		.b_all{
			display: flex;
			align-items: center;
			margin: 58px 0px 23px 34px;
			.b_title1{
				font-size:24px;
				font-weight: 400;
				color: #666666;
			}
			.b_title2{
				font-size:24px;
				font-weight: 400;
				color:#3B68DF;
			}
		}
		.c_content{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 36px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F5F5F5;
			.c_all{
				display: flex;
				align-items: center;
				.c_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.c_title1{
					text-align: left;
					.c_title2{
						font-size:20px;
						font-weight: 400;
						color: #333333;
					}
					.c_title3{
						margin: 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #999999;
					}
					.c_title4{
						display: flex;
						align-items: center;
						.c_title5{
							width: 41px;
							height: 24px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #337CFF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title6{
							width: 37px;
							height: 24px;
							background: #FFF1F0;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #FF4D4F;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title7{
							width: 33px;
							height: 24px;
							background: #DDF7E2;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color:#3DB159;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title8{
							width: 55px;
							height: 24px;
							background: #F0F5FF;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color:#3B68DF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			.c_title9{
				.iconfont{
					color: #FECB1E;
					font-size: 30px;
				}
				.c_title10{
					font-size: 30px;
					font-weight: 400;
					margin-left: 9px;
					color: #FECB1E;
				}
			}
		}
		.search_all{
			height: 179px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF;
		.search{
			width: 350px;
			height: 83px;
			background: #337CFF;
			opacity: 1;
			border-radius: 7px;
			font-size:28px;
			font-weight: 400;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		}
		.a_content{
			display: flex;
			// margin-top: 50px;
			height: 100px;
			align-items: center;
			.a_title9{
				font-size:24px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.d_content{
			width: 1200px;
			background: #F0F5FF;
			border: 5px solid #407EF6;
			padding: 35px 45px;
			box-sizing: border-box;
			margin: 35px 0px;
			.d_title1{
				font-size: 24px;
				font-weight: 500;
				color: #337CFF;
				text-align: left;
			}
			.d_title2{
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 35px;
				color: #666666;
				margin-top: 20px;
				text-align: left;
			}
		}
		.f_content{
			width: 1200px;
			padding: 35px 41px;
			background: #FFFFFF;
			box-sizing: border-box;
			font-size:20px;
			font-weight: 400;
			color: #666666;
			text-align: left;
		}
		.f_title1{
			display: flex;
			align-items: center;
			width: 1200px;
			height: 63px;
			background: #F2F8FF;
			margin-top: 35px;
			.f_title5{
				font-size:20px;
				font-weight: 400;
				margin:0px 58px 0px 49px;
			}
			.f_title6{
				font-size: 18px;
				font-weight: 400;
				color: #337CFF;
			}
			// .f_title7{
			// 	.f_title8{
		
			// 	}
			// }
			.f_title2{
				width: 289px;
				height: 60px;
				background: #337CFF;
				font-size:20px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.f_title2:hover{
				cursor: pointer;
			}
			.f_title3{
				width: 289px;
				height: 60px;
				background: #F5F5F5;
				font-size:20px;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.f_title3:hover{
				cursor: pointer;
			}
		}
		
		.f_title111{
			display: flex;
			align-items: center;
			width: 1200px;
			height: 23px;
			background: #F2F8FF;
			margin-top: 35px;
			.f_title5{
				font-size:20px;
				font-weight: 400;
				margin:0px 58px 0px 49px;
			}
			.f_title6{
				font-size: 18px;
				font-weight: 400;
				color: #337CFF;
			}
			// .f_title7{
			// 	.f_title8{

			// 	}
			// }
			.f_title2{
				width: 289px;
				height: 60px;
				background: #337CFF;
				font-size:20px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.f_title3{
				width: 289px;
				height: 60px;
				background: #F5F5F5;
				font-size:20px;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.f_all{
			display: flex;
			flex-wrap: wrap;
			padding: 37px 37px 0px 37px;
			box-sizing: border-box;
			background: #FFFFFF;
			.f_title4:hover{
				cursor: pointer;
			}
			.f_title4{
				padding: 12px 47px;
				box-sizing: border-box;
				background: #F3F5F6;
				font-size: 18px;
				font-weight: 400;
				color: #999999;
				margin: 0px 35px 23px 0px;
			}
		}
		.g_content{
			background-color: #FFFFFF;
			width: 1200px;
			margin: auto;
			padding: 30px 0px;
			box-sizing: border-box;
			.g_all{
				display: flex;
				align-items: center;
				.g_img{
					width: 100px;
					height: 100px;
					margin:0px 20px 0px 30px;
					border-radius: 50%;
				}
				.g_title1{
					.g_title2{
						display: flex;
						align-items: center;
						.g_title3{
							font-size:24px;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}
						.g_title4{
							width: 47px;
							height: 21px;
							background: #337CFF;
							border-radius: 10px;
							font-size: 12px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.g_title5{
						display: flex;
						align-items: center;
						margin-top: 26px;
						.g_title6{
							font-size: 16px;
							font-weight: 400;
							color: #999999;
							margin-right: 30px;
						}
					}
				}
			}
			.g_title7{
				width: 1140px;
				height: 0px;
				border: 2px solid #CCCCCC;
				margin:22px auto;
			}
			.gg_content{
				padding: 0px 30px;
				box-sizing: border-box;
				background-color: #FFFFFF;
				.gg_title1{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-bottom: 10px;
					text-align: left;
				}
				.gg_all{
					display: flex;
					.gg_title2{
						margin-right: 108px;
						text-align: left;
						.gg_title3{
							font-size: 16px;
							font-weight: 400;
							color: #666666;
							margin-bottom: 10px;
						}
					}
				}
			}
			.g_content1{
				display: flex;
				justify-content:space-between;
				.g_next1{
					width: 200px;
					height: 55px;
					background: #F2F8FF;
					font-size: 20px;
					font-weight: 400;
					color: #337CFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.g_next2{
					width: 200px;
					height: 55px;
					font-size: 20px;
					font-weight: 400;
					color:#333333;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.g_title8{
				width: 1200px;
				height: 2px;
				background: #337CFF;
			}
			.g_title9{
				.g_title10{
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					margin: 30px 0px 20px 30px;
					text-align: left;
				}
				.g_title11{
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					margin:0px 30px 0px 30px;
				}
			}
		}
	}
</style>
